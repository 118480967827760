import Downloading from '@mui/icons-material/Downloading';
import FilterList from '@mui/icons-material/FilterList';
import FilterListOff from '@mui/icons-material/FilterListOff';
import GridColumnIcon from '@mui/icons-material/ListAlt';
import Refresh from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import debounce from '@mui/material/utils/debounce';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';

import { GridLoad } from './Loading';
import { useResize } from './hooks';
import PropTypes from 'prop-types';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { InputAdornment } from '@mui/material';

const BooleanInput = (props) => {
  const { operator, column, applyValue, ...others } = props;
  return (
    <Select variant="standard" fullWidth {...others}>
      <MenuItem value="">-----</MenuItem>
      <MenuItem value="true">true</MenuItem>
      <MenuItem value="false">false</MenuItem>
    </Select>
  );
};

const NumberInput = (props) => {
  const { operator, column, applyValue, ...others } = props;
  return <Input type="number" {...others} fullWidth />;
};

const TextInput = (props) => {
  const { operator, column, applyValue, ...others } = props;
  return <Input type="text" {...others} fullWidth />;
};

const DateInput = (props) => {
  const datePickerRef = useRef();
  const { operator, column, applyValue, value, ...others } = props;
  if (operator === 'between') {
    return (
      <DatePicker
        ref={datePickerRef}
        range
        render={
          <Input
            startAdornment={
              <InputAdornment position="start">
                <CalendarTodayIcon fontSize="small" />
              </InputAdornment>
            }
          />
        }
        dateSeparator=" to "
        className="range-picker"
        onChange={(e) => {
          const [from, to] = e;
          if (from && to) {
            others.onChange({ target: { value: `${from},${to}` } });
            setTimeout(() => {
              //set a small delay to show users dates selected
              datePickerRef?.current?.closeCalendar();
            }, 5);
          }
        }}
      />
    );
  }
  return <Input type="date" {...others} fullWidth />;
};

const SelectInput = (props) => {
  let { operator, column, applyValue, value, ...others } = props;
  if (operator === 'isAnyOf') {
    value = Array.isArray(value) ? value : [value];
  } else {
    value = Array.isArray(value) ? (value.length > 0 ? value[0] : '') : value;
  }
  return (
    <Select
      multiple={operator === 'isAnyOf'}
      variant="standard"
      fullWidth
      value={value}
      {...others}
    >
      <MenuItem value="">
        <Typography variant="i">-----</Typography>
      </MenuItem>
      {column.valueOptions.map((opt) => (
        <MenuItem value={opt.value} key={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const filterOperators = {
  boolean: ['is'].map((value) => ({
    label: value,
    value,
    InputComponent: BooleanInput,
    getApplyFilterFn: (value) =>
      value == null || value?.trim() === '' ? null : value,
  })),
  number: ['=', '!=', '>', '>=', '<', '<=', 'isEmpty', 'isNotEmpty'].map(
    (value) => ({
      label: value,
      value,
      InputComponent: NumberInput,
      getApplyFilterFn: (value) =>
        value == null || value === '' ? null : value,
    })
  ),
  text: [
    ['contains', '% contains'],
    ['equals', '== equals'],
    ['startsWith', '^ starts with'],
    ['endsWith', '$ ends with'],
    ['isEmpty', '~ is empty'],
    ['isNotEmpty', '! is not empty'],
    ['isAnyOf', '* is any of'],
  ].map(([value, label]) => ({
    label,
    value,
    InputComponent: TextInput,
    getApplyFilterFn: (value) => (value == null || value === '' ? null : value),
  })),
  dateTime: [
    ['is', '=   is'],
    ['not', '!   is not'],
    ['after', '> is after'],
    ['onOrAfter', '>= is on or after'],
    ['before', '< is before'],
    ['onOrBefore', '<= is on or before'],
    ['isEmpty', '~ is empty'],
    ['isNotEmpty', '! is not empty'],
    ['between', 'between'],
  ].map(([value, label]) => ({
    label,
    value,
    InputComponent: DateInput,
    getApplyFilterFn: (value) => (value == null || value === '' ? null : value),
  })),
  date: [
    ['is', '=   is'],
    ['not', '!  is not'],
    ['after', '> is after'],
    ['onOrAfter', '>= is on or after'],
    ['before', '< is before'],
    ['onOrBefore', '<= is on or before'],
    ['isEmpty', '~ is empty'],
    ['isNotEmpty', '! is not empty'],
    ['between', 'between'],
  ].map(([value, label]) => ({
    label,
    value,
    InputComponent: DateInput,
    getApplyFilterFn: (value) => (value == null || value === '' ? null : value),
  })),
  singleSelect: [
    ['is', '=   is'],
    ['not', '!  is not'],
    ['isAnyOf', '*  is any of'],
  ].map(([value, label]) => ({
    label,
    value,
    InputComponent: SelectInput,
    getApplyFilterFn: (value) => (value == null || value === '' ? null : value),
  })),
};

export const OparetaTable = ({
  columns,
  getRowId,
  initialState,
  loading,
  onCellClick,
  onColumnVisibilityModelChange,
  onExportClick,
  onFilterModelChange,
  onPageChange,
  onPageSizeChange,
  onSortModelChange,
  page,
  rows,
  width = 'auto',
  ActionButtons,
  showToolbar = true,
  showResetButton = true,
  hideBorder = false,
}) => {
  const paperRef = useRef();
  const [height, setHeight] = useState(window.innerHeight);
  const [showFilters, setShowFilters] = useState(() => {
    const shown = localStorage.getItem('showFilters') ?? '0';
    return shown === '1';
  });
  const { width: paperWidth, offsetTop } = useResize(paperRef);
  const paperHeight = height - offsetTop - 10;
  const rowsPerPage = Math.floor(
    (paperHeight - 40 * (showFilters ? 4 : 3)) / 40
  );
  // console.log('RXD: width', { paperWidth });
  const rowsPerPageRef = useRef(rowsPerPage);

  const seenColumns = [];
  const uniqueColumns = [];
  for (let column of columns) {
    if (seenColumns.indexOf(column.field) === -1) {
      seenColumns.push(column.field);
      uniqueColumns.push(column);
    }
  }
  columns = uniqueColumns;

  const [model, setModel] = useState(function () {
    const filterMap = new Map(
      (initialState?.filter?.filterModel?.items ?? []).map((filter) => [
        filter.field,
        filter,
      ])
    );
    const sortMap = new Map(
      (initialState?.sorting?.sortModel ?? []).map((sort) => [
        sort.field,
        sort.sort,
      ])
    );

    return new Map(
      columns.map((column) => {
        return [
          column.field,
          {
            visible:
              initialState?.columns?.columnVisibilityModel?.[column.field] ??
              true,
            filter: filterMap.get(column.field)?.value ?? '',
            op: filterMap.get(column.field)?.operator ?? '',
            sort: sortMap.get(column.field) ?? null,
          },
        ];
      })
    );
  });
  const [columnVisibilityEl, setColumnVisibilityEl] = useState(null);
  const [columnVisibilityFilter, setColumnVisibilityFilter] = useState('');

  const handleColumnVisibilityButtonClick = (event) => {
    setColumnVisibilityEl(event.currentTarget);
  };
  const handleColumnVisibilityClose = () => {
    setColumnVisibilityEl(null);
  };
  const handleColumnVisibilityFilter = (event) => {
    setColumnVisibilityFilter(event.target.value);
  };
  const handleColumnVisibilityModelChange = () => {
    const visiblityModel = {};
    Array.from(model.entries()).forEach(([field, columnModel]) => {
      if (!columnModel?.visible) {
        visiblityModel[field] = false;
      }
    });
    onColumnVisibilityModelChange(visiblityModel);
  };
  const openColumnVisibility = Boolean(columnVisibilityEl);
  const columnVisibilityId = openColumnVisibility
    ? 'simple-popover'
    : undefined;

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setHeight(window.innerHeight);
    }, 600);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    localStorage.setItem('showFilters', showFilters ? '1' : '0');
  }, [showFilters]);

  useEffect(() => {
    if (rowsPerPageRef.current !== rowsPerPage) {
      rowsPerPageRef.current = rowsPerPage;
      if (typeof onPageSizeChange === 'function') {
        onPageSizeChange(rowsPerPage);
      }
    }
  }, [onPageSizeChange, rowsPerPage, rowsPerPageRef]);

  const totalFlex = columns
    .filter((column) => model.get(column?.field)?.visible ?? true)
    .map((column) => +(column?.flex ?? 1))
    .reduce((a, b) => a + b, 0);

  const columnWeights = columns.map((column) => {
    let width = `${((column?.flex ?? 1) / totalFlex) * 100}%`;
    let minWidth = null;
    if ('minWidth' in column) {
      minWidth = +column.minWidth;
    }

    return {
      width,
      minWidth,
    };
  });
  // console.log('RXD: ', { totalFlex, columnWeights, columns });

  const hasFilters = () => {
    for (const columnModel of model.values()) {
      if (columnModel.filter) {
        return true;
      }
    }
    return false;
  };

  const PAPER_STYLES = {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    height: paperHeight,
    width,
  };

  if (hideBorder) {
    PAPER_STYLES.border = 'none';
    PAPER_STYLES.borderRadius = '0';
    PAPER_STYLES.boxShadow = 'none';
  }

  return (
    <Paper sx={PAPER_STYLES} ref={paperRef}>
      {showToolbar && (
        <Box>
          <Button
            size="small"
            startIcon={<GridColumnIcon />}
            sx={{ paddingX: 1 }}
            onClick={handleColumnVisibilityButtonClick}
          >
            Columns
          </Button>
          <Button
            size="small"
            startIcon={showFilters ? <FilterListOff /> : <FilterList />}
            sx={{ paddingX: 1 }}
            onClick={(e) => setShowFilters(!showFilters)}
          >
            {hasFilters() && !showFilters && (
              <Box
                sx={{
                  position: 'absolute',
                  width: 8,
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: 'warning.main',
                  left: 13,
                  top: 10,
                }}
              ></Box>
            )}
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </Button>
          {onExportClick && (
            <Button
              size="small"
              startIcon={<Downloading />}
              sx={{ paddingX: 1 }}
              onClick={onExportClick}
            >
              Export
            </Button>
          )}
          {showResetButton && (
            <Button
              size="small"
              startIcon={<Refresh />}
              sx={{ paddingX: 1 }}
              onClick={(e) => {
                Array.from(model.values()).forEach((value) => {
                  value.filter = '';
                  value.op = '';
                  value.sort = null;
                });
                onSortModelChange([]);
                setModel(new Map(model));
                onFilterModelChange({ items: [], columns });
              }}
              elevation={5}
            >
              Reset
            </Button>
          )}
          <Popover
            id={columnVisibilityId}
            open={openColumnVisibility}
            anchorEl={columnVisibilityEl}
            onClose={handleColumnVisibilityClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Card
              sx={{
                minWidth: '300px',
                padding: 1,
              }}
              elevation={5}
            >
              <TextField
                id="standard-basic"
                label="Field column"
                variant="standard"
                size="small"
                fullWidth
                value={columnVisibilityFilter}
                onChange={handleColumnVisibilityFilter}
                sx={{ mb: 1 }}
              />
              <List
                sx={{
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 350,
                  padding: 1,
                }}
              >
                {columns
                  .filter((column) => {
                    if (columnVisibilityFilter) {
                      const r = new RegExp(columnVisibilityFilter, 'i');
                      return r.test(column.headerName) || r.test(column.field);
                    }
                    return true;
                  })
                  .map((column) => {
                    const columnModel = model.get(column.field);
                    return (
                      <li
                        key={`column-visibility-${column.field}`}
                        sx={{ padding: 1 }}
                      >
                        <FormControlLabel
                          required
                          control={
                            <Switch
                              size="small"
                              checked={columnModel?.visible}
                              onChange={(e) => {
                                columnModel.visible = !columnModel.visible;
                                handleColumnVisibilityModelChange();
                                setModel(new Map(model));
                              }}
                            />
                          }
                          label={column.headerName}
                          fullWidth
                        />
                      </li>
                    );
                  })}
              </List>
              <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                <Button
                  onClick={(e) => {
                    Array.from(model.entries()).map(([key, columnModel]) => {
                      columnModel.visible = false;
                      return [key, columnModel];
                    });
                    setModel(new Map(model));
                    handleColumnVisibilityModelChange();
                  }}
                >
                  Hide all
                </Button>
                <Button
                  onClick={(e) => {
                    Array.from(model.entries()).map(([key, columnModel]) => {
                      columnModel.visible = true;
                      return [key, columnModel];
                    });
                    setModel(new Map(model));
                    handleColumnVisibilityModelChange();
                  }}
                >
                  Show all
                </Button>
              </Box>
            </Card>
          </Popover>
          {ActionButtons && (
            <ActionButtons model={model} setModel={setModel} rows={rows} />
          )}
        </Box>
      )}
      {loading && (
        <Box
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            position: 'absolute',
            display: 'flex',
            width: paperWidth,
          }}
        >
          <GridLoad />
        </Box>
      )}
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={'medium'}
          sx={{ tableLayout: 'fixed', width: 'auto', minWidth: '100%' }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                const columnModel = model.get(column.field);
                if (!(columnModel?.visible ?? true)) {
                  return null;
                }
                const direction = columnModel?.sort ?? null;
                const styles1 = {
                  padding: '7px',
                  maxWidth: column?.maxWidth,
                  minWidth: columnWeights[index].minWidth,
                  width: column?.maxWidth ?? columnWeights[index].width,
                  fontWeight: '600',
                };

                if (!!column?.sticky && !!column?.sticky?.left) {
                  styles1.position = 'sticky';
                  styles1.left = column.sticky.left;
                  styles1.backgroundColor = 'white';
                  styles1.zIndex = '2';
                }

                return (
                  <TableCell
                    component="th"
                    key={`th-title-${column?.field}`}
                    padding="none"
                    sx={styles1}
                    align={column?.headerAlign}
                  >
                    {Array.isArray(column?.sortingOrder) ? (
                      <TableSortLabel
                        sx={{
                          width: '100%',
                          justifyContent: column?.headerAlign,
                        }}
                        active={!!columnModel.sort}
                        direction={direction ?? 'asc'}
                        onClick={(e) => {
                          columnModel.sort =
                            column.sortingOrder[
                              (column.sortingOrder.indexOf(direction) + 1) %
                                column.sortingOrder.length
                            ];
                          columnModel.sort = columnModel.sort
                            ? columnModel.sort
                            : null;
                          // console.log('RXD: OparetaTable');
                          // console.log({sort: columnModel.sort,});
                          setModel(new Map(model));
                          onSortModelChange(
                            Array.from(model.entries())
                              .filter(([_, column]) => column.sort)
                              .map(([field, column]) => ({
                                field,
                                sort: column.sort,
                              }))
                          );
                        }}
                      >
                        <Box
                          title={column?.headerName}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {column?.headerName}
                        </Box>
                      </TableSortLabel>
                    ) : (
                      <Box
                        title={column?.headerName}
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {column?.headerName}
                      </Box>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
            {showFilters && (
              <TableRow>
                {columns.map((column) => {
                  const columnModel = model.get(column.field);
                  if (!(columnModel?.visible ?? true)) {
                    return null;
                  }

                  const styles2 = { padding: '7px', overflow: 'hidden' };
                  if (!!column?.sticky && !!column?.sticky?.left) {
                    styles2.position = 'sticky';
                    styles2.left = column.sticky.left;
                    styles2.backgroundColor = 'white';
                    styles2.zIndex = '2';
                  }

                  if (!(column?.filterable ?? true)) {
                    return (
                      <TableCell
                        key={column?.headerName}
                        padding="none"
                        sx={styles2}
                      ></TableCell>
                    );
                  }
                  const type =
                    column?.type &&
                    Object.keys(filterOperators).indexOf(column?.type) > -1
                      ? column?.type
                      : 'text';
                  const operators =
                    column?.filterOperators ?? filterOperators[type];
                  const op = !!columnModel?.op
                    ? columnModel?.op
                    : operators[0].value;
                  columnModel.op = op;
                  const selected = operators.filter((o) => o.value === op);
                  const InputComponent =
                    selected.length === 1 ? selected[0].InputComponent : Input;

                  const applyValue = (value) => {
                    /**
                     * Prevents between value from being sent with a single date
                     * which causes a server error due to between requiring a start and end date
                     * the date is in the format between:2024-05-01,2024-05-09
                     * Incase a person switches from single to date range and vice versa. This will be trigger to clear
                     * previous selected date
                     */
                    columnModel.filter =
                      type.includes('date') && value && columnModel.op !== op
                        ? ''
                        : value;
                    setModel(new Map(model));
                    onFilterModelChange({
                      items: Array.from(model.entries()).map(
                        ([columnField, columnModel]) => ({
                          columnField,
                          value: columnModel.filter,
                          operatorValue: columnModel.op,
                        })
                      ),
                      columns,
                    });
                  };
                  const debounceApplyValue = debounce(applyValue, 1000);

                  const styles3 = { padding: '7px', overflow: 'hidden' };
                  if (!!column?.sticky && !!column?.sticky?.left) {
                    styles3.position = 'sticky';
                    styles3.left = column.sticky.left;
                    styles3.backgroundColor = 'white';
                    styles3.zIndex = '2';
                  }

                  return (
                    <TableCell
                      key={column?.headerName}
                      padding="none"
                      sx={styles3}
                    >
                      {column.filter !== false && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Select
                            multiple={false}
                            variant="standard"
                            value={op}
                            onChange={(e) => {
                              columnModel.op = e.target.value;
                              debounceApplyValue(columnModel.filter);
                            }}
                            sx={{
                              flexBasis: '37px',
                              width: '37px',
                              flexGrow: 0,
                              '& .MuiSelect-select': {
                                textOverflow: 'clip',
                                minWidth: 0,
                              },
                              '& .MuiSelect-icon': {
                                backgroundColor: 'white',
                                width: '25.5px',
                              },
                            }}
                          >
                            {operators.map((operator) => (
                              <MenuItem
                                value={operator.value}
                                key={operator.value}
                              >
                                {operator?.label ?? operator.value}
                              </MenuItem>
                            ))}
                          </Select>
                          <InputComponent
                            operator={op}
                            applyValue={applyValue}
                            column={column}
                            value={columnModel?.filter}
                            fullWidth
                            onChange={(e) => {
                              applyValue(e.target.value);
                            }}
                          />
                        </Box>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {Array(!rowsPerPage || rowsPerPage < 0 ? 0 : rowsPerPage)
              .fill(0)
              .map((_, index) => {
                // const isItemSelected = isSelected(row.name);
                // const labelId = `enhanced-table-checkbox-${index}`;
                if (rows.length <= index) {
                  return (
                    <TableRow key={`${index}`}>
                      {columns.map((column, i2) => {
                        const columnModel = model.get(column.field);
                        if (!(columnModel?.visible ?? true)) {
                          return null;
                        }

                        const styles4 = {
                          padding: '7px',
                          height: 39,
                          maxWidth: column?.maxWidth,
                          minWidth: columnWeights[i2].minWidth,
                          width: column?.maxWidth ?? columnWeights[i2].width,
                        };

                        if (!!column?.sticky && !!column?.sticky?.left) {
                          styles4.position = 'sticky';
                          styles4.left = column.sticky.left;
                          styles4.backgroundColor = 'white';
                          styles4.zIndex = '2';
                        }

                        return (
                          <TableCell key={`${index}-${i2}`} sx={styles4}>
                            <Box
                              sx={{
                                width: '100%',
                              }}
                            >
                              {' '}
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }
                const row = rows[index];
                const key = getRowId(row);
                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      if (typeof onCellClick === 'function') {
                        onCellClick({ row }, event);
                      }
                    }}
                    tabIndex={-1}
                    key={key}
                    sx={{ cursor: 'pointer' }}
                  >
                    {columns.map((column, i2) => {
                      const columnModel = model.get(column.field);
                      if (!(columnModel?.visible ?? true)) {
                        return null;
                      }
                      const defaultValueGetter = (param) =>
                        param.row[column.field];

                      const valueGetter = (param) => {
                        const getter = column?.valueGetter
                          ? column.valueGetter
                          : defaultValueGetter;

                        try {
                          return getter(param);
                        } catch (err) {
                          console.error({ err });
                        }
                        return '';
                      };

                      const renderCell = column?.renderCell
                        ? column.renderCell
                        : valueGetter;

                      const styles5 = {
                        padding: '7px',
                        height: 39,
                        maxWidth: column?.maxWidth,
                        minWidth: columnWeights[i2].minWidth,
                        width: column?.maxWidth ?? columnWeights[i2].width,
                        ...column?.sx,
                      };

                      if (!!column?.sticky && !!column?.sticky?.left) {
                        styles5.position = 'sticky';
                        styles5.left = column.sticky.left;
                        styles5.backgroundColor = 'white';
                        styles5.zIndex = '2';
                      }

                      return (
                        <TableCell
                          component="td"
                          padding="none"
                          key={`${key}-${column.field}`}
                          align={column?.align}
                          sx={styles5}
                        >
                          <Box
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '100%',
                            }}
                            title={`${valueGetter({ row })}`}
                          >
                            {renderCell({ row })}
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={'div'}
        count={
          rows.length < rowsPerPage ? page * rowsPerPage + rows.length : -1
        }
        rowsPerPageOptions={[]}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(event, page) => {
          if (onPageChange) {
            onPageChange(page, event);
          }
        }}
        labelDisplayedRows={({ from, to, count }) => {
          return `Page: ${page + 1} (${from}–${to})`;
        }}
      />
    </Paper>
  );
};

OparetaTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string,
      filterable: PropTypes.bool,
      minWidth: PropTypes.number,
      maxWidth: PropTypes.number,
      // sortingOrder: PropTypes.arrayOf(['asc', 'desc', null, false]),
      renderCell: PropTypes.func,
      valueGetter: PropTypes.func,
      type: PropTypes.oneOf([
        'boolean',
        'date',
        'dateTime',
        'number',
        'singleSelect',
        'text',
        'none',
      ]),
    })
  ),
  getRowId: PropTypes.func,
  initialState: PropTypes.shape({
    sorting: PropTypes.shape({
      sortModel: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          sort: PropTypes.oneOf(['desc', 'asc', null]),
        })
      ),
    }),
    filter: PropTypes.shape({
      filterModel: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.any,
            field: PropTypes.string,
            operator: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
    }),
    columns: PropTypes.shape({
      columnVisibilityModel: PropTypes.objectOf(PropTypes.bool),
    }),
  }),
  loading: PropTypes.bool,
  onCellClick: PropTypes.func,
  onColumnVisibilityModelChange: PropTypes.func,
  onExportClick: PropTypes.func,
  onFilterModelChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  page: PropTypes.number,
  rows: PropTypes.array.isRequired,
  width: PropTypes.number,
  ActionButtons: PropTypes.elementType,
};
