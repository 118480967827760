import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ClearReassignmentsDialog = ({
  open,
  onClose,
  onConfirm,
  clearing,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Are you sure you want to clear the agent(s) from the list?
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          This implies that the agent(s) will be removed from the list but will
          not be reassigned to the new Team Field Agent.
        </DialogContentText>
      </DialogContent>

      {clearing && (
        <DialogContent sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </DialogContent>
      )}

      {!clearing && (
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button variant="contained" onClick={onClose} color="error">
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm} autoFocus>
            Yes, I confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
