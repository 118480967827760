import { createSlice } from '@reduxjs/toolkit';
import { getApi, getApiError } from '@/common';

const initialState = {
  agent: null,
  isLoading: false,
  error: '',
  formError: '',
};

const agentSlice = createSlice({
  name: 'agent',
  initialState: initialState,
  reducers: {
    setAgent: (state, { payload }) => {
      state.agent = payload;
      state.isLoading = false;
      state.error = '';
    },
    resetState: (state) => {
      state.agent = null;
      state.isLoading = false;
      state.error = '';
      state.formError = '';
    },
    setLoading: (state) => {
      state.error = '';
      state.formError = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    setFormError: (state, { payload }) => {
      state.formError = payload;
      state.isLoading = false;
    },
  },
});

export const agentFetch = (uuid) => (dispatch) => {
  dispatch(setLoading(true));
  getApi()
    .get(`/api/agents/${uuid}`)
    .then((response) => dispatch(setAgent(response.data)))
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const agentVerify = (uuid) => (dispatch) => {
  const data = { status: 'active', isVerified: true };
  dispatch(setLoading());
  getApi()
    .patch(`/api/agents/${uuid}`, data)
    .then((response) => dispatch(setAgent(response.data)))
    .catch((err) => dispatch(setFormError(getApiError(err))));
};

export const agentVerifyExternalId = (uuid, ezeeMoneyScCode) => (dispatch) => {
  dispatch(setLoading());
  getApi()
    .post(`/api/agents/${uuid}/ezeemoney-sc-code`, { ezeeMoneyScCode })
    .then((response) => dispatch(setAgent(response.data)))
    .catch((err) => dispatch(setFormError(getApiError(err))));
};

export const { setAgent, resetState, setLoading, setError, setFormError } =
  agentSlice.actions;

export const agentReducer = agentSlice.reducer;
