import { AppBar, TabPanel, useFilters } from '@/common';
import { FEATURES, hasFeature } from '@/common/features';
import { a11yProps } from '@/common/TabPanel';
import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldAgentAssignForm } from '..';
import { useSelector } from 'react-redux';
import { ReassignmentsTableView } from './reassignments/ReassignmentsTableView';
import { ReassignmentsMapView } from './reassignments/ReassignmentsMapView';

export const ManageTerritories = ({ embedded }) => {
  const [filters, setFilters] = useFilters();

  const {
    locations,
    isLoading: locationsIsLoading,
    error: locationsError,
  } = useSelector((state) => state.locations);

  const [tab, setTab] = useState(0);
  const [country, setCountry] = useState({ name: '...' });

  useEffect(() => {
    const _tab = Number(filters.get('tab'));
    if (!isNaN(_tab)) setTab(_tab);
  }, [filters]);

  useEffect(() => {
    if (
      country.name == '...' &&
      !locationsIsLoading &&
      !locationsError &&
      locations.length > 0
    ) {
      setCountry(locations[0]);
    }
  }, [locations, locationsIsLoading, locationsError, country]);

  const handleTabChange = (event, index) => {
    const newFilters = new URLSearchParams();
    newFilters.set('tab', index);
    setFilters(newFilters);
  };

  return (
    <>
      <AppBar>Manage Territories : {!country ? '...' : country.name}</AppBar>

      <Tabs
        value={tab}
        onChange={handleTabChange}
        sx={{ background: 'white', marginBottom: '1rem' }}
        aria-label="manage territories detail tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Assign Territories" value={0} {...a11yProps(0)} />
        <Tab label="Agent Table" value={1} {...a11yProps(1)} />
        <Tab label="Agent Map View" value={2} {...a11yProps(2)} />
      </Tabs>

      <TabPanel value={tab} index={0}>
        <FieldAgentAssignForm embedded={true} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ReassignmentsTableView />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ReassignmentsMapView />
      </TabPanel>
    </>
  );
};
