import { OparetaTable } from '@/common/OparetaTable';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReassignments,
  confirmReassignments,
  reassignmentListExport,
  reassignmentListFetch,
  setUpdatingReassignments,
} from './ReassignmentListSlice';
import { useEffect, useState } from 'react';
import {
  getColumnVisibility,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import dayjs from 'dayjs';
import { Clear, Repeat } from '@mui/icons-material';
import { ConfirmReassignmentsDialog } from './ConfirmReassignmentsDialog';
import { ClearReassignmentsDialog } from './ClearReassignmentsDialog';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { paginationValue } from '@/common/filter';
import { hasNewFieldAgent } from './hasNewFieldAgent';

export const ReassignmentsTableView = ({ embedded }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  const {
    reassignmentList,
    isLoading,
    error,
    lastFilter,
    updatingReassignments,
  } = useSelector((state) => {
    return state.reassignmentList;
  });

  const [confirmReassignmentsDialogOpen, setConfirmReassignmentsDialogOpen] =
    useState(false);

  const [confirmingReassignments, setConfirmingReassignments] = useState(false);

  const [clearReassignmentsDialogOpen, setClearReassignmentsDialogOpen] =
    useState(false);
  const [clearingReassignments, setClearingReassignments] = useState(false);

  const [reassignmentIds, setReassignmentIds] = useState([]);

  const loadCurrentFilters = () => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'reassignment.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'agent.agentName');
    }
    return currentFilters;
  };

  useEffect(() => {
    const currentFilters = loadCurrentFilters();
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(reassignmentListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [dispatch, filters, isLoading, lastFilter, setFilters, error, embedded]);

  useEffect(() => {
    const currentFilters = loadCurrentFilters();
    dispatch(reassignmentListFetch(currentFilters));
    dispatch(setUpdatingReassignments(false));
    hasReassignments();
  }, []);

  useEffect(() => {
    const processing = updatingReassignments?.payload ?? false;
    if (!processing) {
      if (clearingReassignments) {
        setClearReassignmentsDialogOpen(false);
        setClearingReassignments(false);
        const currentFilters = loadCurrentFilters();
        dispatch(reassignmentListFetch(currentFilters));
      } else if (confirmingReassignments) {
        setConfirmReassignmentsDialogOpen(false);
        setConfirmingReassignments(false);
        const currentFilters = loadCurrentFilters();
        dispatch(reassignmentListFetch(currentFilters));
      }
    }
  }, [updatingReassignments]);

  const w = (callback) => (param) => callback(param.row);

  const startReassign = () => {
    setConfirmReassignmentsDialogOpen(true);
    setConfirmingReassignments(false);
  };

  const startClear = () => {
    setClearReassignmentsDialogOpen(true);
    setClearingReassignments(false);
  };

  const cancelReassign = () => {
    setConfirmReassignmentsDialogOpen(false);
  };

  const confirmReassign = () => {
    setConfirmingReassignments(true);
    dispatch(confirmReassignments(reassignmentIds));
  };

  const cancelClear = () => {
    setClearReassignmentsDialogOpen(false);
  };

  const confirmClear = () => {
    setClearingReassignments(true);
    dispatch(clearReassignments(reassignmentIds));
  };

  const columns = [
    {
      field: 'isChecked',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      flex: 0.2,
      sx: { padding: '0px' },
      sortingOrder: false,
      filterable: false,
      sticky: {
        left: '0',
      },
      renderCell: w((reassignment) => {
        return (
          <Checkbox
            size="small"
            checked={reassignmentIds.includes(reassignment.uuid)}
            onChange={(e) => {
              const uniqIds = new Set(reassignmentIds);
              if (e.target.checked) {
                uniqIds.add(reassignment.uuid);
              } else {
                uniqIds.delete(reassignment.uuid);
              }
              const newReassignmentIds = Array.from(uniqIds);
              setReassignmentIds(newReassignmentIds);
            }}
          />
        );
      }),
    },
    {
      headerName: 'Agent Name',
      field: 'agent.agentName',
      minWidth: 200,
      sticky: {
        left: '50px',
      },
      valueGetter: w((reassignment) => reassignment.agent?.agentName),
      sortingOrder: getSortOrder(),
      filterable: true,
    },
    {
      headerName: 'Agent Number',
      minWidth: 200,
      field: 'agentNumber',
      valueGetter: w(
        (reassignment) => reassignment.agent?.simNumbers[0]?.phoneNumber
      ),
      filterable: true,
    },
    {
      headerName: 'Old Territory',
      minWidth: 200,
      field: 'oldTerritoryLocations.name',
      valueGetter: w((reassignment) => {
        let oldTerritoryName = '';
        const noTerritories =
          reassignment.oldFieldAgent?.territories?.length || 0;
        const names = [];
        for (let i = 0; i < noTerritories; i++) {
          const territory = reassignment.oldFieldAgent.territories[i];
          const name = territory.location.name;
          names.push(name);
        }

        if (names.length > 0) {
          oldTerritoryName = names.join(' | ');
        } else {
          oldTerritoryName = '...';
        }

        return oldTerritoryName;
      }),
      filterable: true,
    },
    {
      headerName: 'Field Agent',
      minWidth: 200,
      field: 'oldFieldAgent.name',
      valueGetter: w((reassignment) => reassignment.oldFieldAgent?.name),
      sortingOrder: getSortOrder(),
      filterable: true,
    },
    {
      headerName: 'New Field Agent',
      minWidth: 200,
      field: 'newFieldAgent.name',
      renderCell: w((reassignment) => {
        let result = '...';

        if (hasNewFieldAgent(reassignment)) {
          result = (reassignment?.newTerritory?.territories || [])[0]
            ?.fieldAgent?.name;
        } else {
          result = (
            <Typography color="error" sx={{ fontSize: '0.7rem' }}>
              No field agent assigned to territory
            </Typography>
          );
        }

        return result;
      }),
      filterable: true,
    },
    {
      headerName: 'New Territory',
      minWidth: 200,
      field: 'newLocation.name',
      valueGetter: w((reassignment) => reassignment.newTerritory?.name),
      filterable: true,
    },
    {
      headerName: 'Time in weeks',
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      field: 'timeInWeeks',
      valueGetter: w((reassignment) => {
        const today = dayjs();
        const addedAt = dayjs(reassignment.addedToNewTerritoryAt);
        const timeInWeeks = today.diff(addedAt, 'week');
        return timeInWeeks;
      }),
      filterable: false,
    },
    {
      headerName: 'Action',
      minWidth: 250,
      field: 'action',
      filterable: false,
      renderCell: w((reassignment) => {
        return (
          <Grid
            container
            spacing={4}
            sx={{
              alignItems: 'center',
            }}
          >
            <Grid item>
              {hasNewFieldAgent(reassignment) && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    /*
                    NB : because we are only updating one record
                    we need to replace the array with a new one
                  */
                    const newReassignmentIds = [reassignment.uuid];
                    setReassignmentIds(newReassignmentIds);
                    startReassign();
                  }}
                >
                  Reassign
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="text"
                endIcon={<Clear fontSize="small" />}
                sx={{
                  color: 'black',
                }}
                onClick={() => {
                  /*
                    NB : because we are only updating one record
                    we need to replace the array with a new one
                  */
                  const newReassignmentIds = [reassignment.uuid];
                  setReassignmentIds(newReassignmentIds);
                  startClear();
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        );
      }),
    },
  ];

  const hasReassignments = () => {
    return (
      filters.size > 3 || (!!reassignmentList && reassignmentList.length > 0)
    );
  };

  return (
    <>
      <Grid>
        {!hasReassignments() && (
          <Box
            sx={{
              backgroundColor: 'white',
              border: '0.5px solid rgba(0,0,0,0.2)',
              borderRadius: '1rem',
              padding: '2rem',
              height: '65vh',
            }}
          >
            No pending reassignments to show
          </Box>
        )}

        {hasReassignments() && (
          <>
            <ConfirmReassignmentsDialog
              open={confirmReassignmentsDialogOpen}
              confirming={confirmingReassignments}
              onClose={cancelReassign}
              onConfirm={confirmReassign}
            />

            <ClearReassignmentsDialog
              open={clearReassignmentsDialogOpen}
              clearing={clearingReassignments}
              onClose={cancelClear}
              onConfirm={confirmClear}
            />

            <OparetaTable
              showResetButton={true}
              getRowId={(reassignment) => reassignment.uuid}
              rows={reassignmentList ?? []}
              onColumnVisibilityModelChange={(visible) =>
                setColumnVisibility('ReassignmentList', visible)
              }
              columns={columns}
              onExportClick={() => dispatch(reassignmentListExport())}
              loading={isLoading}
              page={serverPage - 1}
              onFilterModelChange={getOnFilterModelChange(
                filters,
                setFilters,
                serverPerPage
              )}
              onSortModelChange={getOnSortModelChange(
                filters,
                setFilters,
                serverPerPage
              )}
              onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
              onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
              ActionButtons={({ model, setModel, rows }) => {
                return (
                  <>
                    <Button
                      size="small"
                      startIcon={<Repeat fontSize="small" />}
                      onClick={startReassign}
                    >
                      Reassign
                    </Button>
                    <Button
                      size="small"
                      startIcon={<Clear fontSize="small" />}
                      onClick={startClear}
                    >
                      Clear
                    </Button>
                  </>
                );
              }}
              initialState={{
                filter: getInitialFilterModel(filters, columns),
                columns: {
                  columnVisibilityModel: getColumnVisibility(
                    'ReassignmentList',
                    {}
                  ),
                },
              }}
            />
          </>
        )}
      </Grid>
    </>
  );
};
