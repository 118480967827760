import { dateFormat, numberFormat } from '@/common';
import { EZEEMONEY_OWNER_TYPE } from '@/common/constants';

const MAX_KYC_STEPS = 15;

export const hasPosition = (agent) => Boolean(agent?.lastLocation);

export const getPosition = (agent) => agent?.lastLocation?.coordinates;

export const formattedCount = (agent) => numberFormat(agent?.transactionCount);

export const formattedVolume = (agent) =>
  numberFormat(agent?.transactionVolume);

export const getBirthday = (agent) =>
  dateFormat(agent?.birthday, 'DD MMM, YYYY') ?? '-';

export const lastActiveAt = (agent) => dateFormat(agent?.lastActiveAt) ?? '-';

export const verifiedAt = (agent) => dateFormat(agent?.verifiedAt) ?? '-';

export const updatedAt = (agent) => dateFormat(agent?.updatedAt);

export const createdAt = (agent) => dateFormat(agent?.createdAt);

export const getFieldAgent = (agent) => agent?.fieldAgent?.name ?? '';

export const getTradingName = (agent) => agent?.tradingName ?? '';

export const getBusinessAddress = (agent) => agent?.location ?? '';

export const getFromDiscovery = (agent) =>
  agent?.additionalData?.createdByAgentDiscoveryForm ?? false;

export const getPhysicalLocation = (agent) =>
  agent?.physicalLocation?.name ?? '';

export const isExposed = (exposure) => !/^(no|)$/i.test(exposure);

export const getExposure = (exposure) => {
  if (isExposed(exposure)) {
    return exposure;
  }
  return '';
};

export const getAgentStatus = (agent) => {
  switch (agent?.contract?.status) {
    case 'completed':
    case 'cleared':
      return 'cleared';
    case 'pre-cleared':
      return 'pre-cleared';
    case 'field-cleared':
      return 'field-cleared';
    case 'refused':
      return 'refused';
    case 'pending':
      return 'pending';
    case 'needs-update':
      return 'needs-update';
    case 'not-started':
    case '':
    case null:
    case undefined:
      return 'not-started';
    default:
      return agent?.contract?.status;
  }
};

export const getComplianceStatusBackground = (status) => {
  switch (status) {
    case 'completed':
    case 'cleared':
    case 'pre-cleared':
    case 'field-cleared':
      return '#21CE99';
    case 'refused':
      return '#F9595C';
    case 'pending':
    case 'needs-update':
      return '#FDBF39';
    default:
      return '#dddddd';
  }
};

export const getAgentStatusBackground = (agent) =>
  getComplianceStatusBackground(agent?.contract?.status);

export const getComplianceStatusTextColor = (status) => {
  switch (status) {
    case 'completed':
    case 'cleared':
    case 'pre-cleared':
    case 'field-cleared':
      return '#ffffff';
    case 'refused':
      return '#ffeeee';
    case 'pending':
    case 'needs-update':
      return '#fdeedd';
    default:
      return '#555555';
  }
};

export const getAgentStatusTextColor = (agent) =>
  getComplianceStatusTextColor(agent?.contract?.status);

export const getOnboardedFieldAgent = (agent) =>
  agent?.onboardedFieldAgent?.name ?? '';

export const getKyaFieldAgent = (agent) => agent?.kyaFieldAgent?.name ?? null;

export const getKycProgress = (agent) =>
  agent?.step === null
    ? 'Not Started'
    : agent.step === MAX_KYC_STEPS
      ? 'Completed'
      : `${agent.step}/${MAX_KYC_STEPS}`;

export const getKycBackgroundColor = (agent) =>
  agent?.step === null
    ? '#ffcec9'
    : agent.step === MAX_KYC_STEPS
      ? '#DCF2EA'
      : `${agent.step}/${MAX_KYC_STEPS}`;

export const getBusinessTypes = () => [
  'Boutique',
  'Market Stall',
  'Mobile Money Kiosk',
  'Shop',
  'Other',
];

export const getReferredByOptions = () => [
  'agent',
  'field_agent',
  'social',
  'other',
];

export const getSimNumberCategories = () => {
  const env = import.meta.env?.VITE_ENV;
  const base = ['CONTACT', 'WHATSAPP'];
  switch (env) {
    case 'staging_sl':
    case 'production_sl':
      return [...base, 'SL_ORANGE', 'SL_QCELL'];
    case 'staging_afrimoney_angola':
    case 'production_afrimoney_angola':
      return [...base, 'AO_AFRICELL'];
    case 'staging_afrimoney_gambia':
    case 'production_afrimoney_gambia':
      return [...base, 'GM_AFRICELL'];
    case 'staging_cyad_drc':
    case 'production_cyad_drc':
      return [...base, 'CD_MPESA', 'CD_VODACOM'];
    case 'staging_mobicom_zambia':
    case 'production_mobicom_zambia':
      return [...base, 'ZM_AIRTEL', 'ZM_MTN'];
    case 'staging_opareta':
    case 'production_opareta':
      return [...base, 'UG_AIRTEL', 'UG_CONTACT', 'UG_MTN'];
    default:
      return base;
  }
};

export const getSimCategory = (category) => {
  switch (category) {
    case 'UG_AIRTEL':
    case 'ZM_AIRTEL':
      return 'Airtel Money';
    case 'UG_MTN':
    case 'ZM_MTN':
      return 'MTN Money';
    case 'AO_AFRICELL':
    case 'GM_AFRICELL':
    case 'SL_AFRICELL':
      return 'Afrimoney';
    case 'CD_VODACOM':
      return 'Vodacom';
    case 'CD_MPESA':
      return 'MPesa';
    case 'SL_ORANGE':
      return 'Orange';
    case 'SL_QCELL':
      return 'QCell';
    case 'CONTACT':
    case 'UG_CONTACT':
      return 'Contact';
    default:
      return category;
  }
};

export const shouldShowExternalIdOnVerify = (agent) => {
  return agent?.ownerType == EZEEMONEY_OWNER_TYPE;
};

export const shouldSetSimNumberAgentId = (simNumber) => {
  return simNumber?.category == 'UG_CONTACT';
};

export const getCurrencyGrouped = (simNumbers) => {
  const grouped = [];
  for (let s of simNumbers) {
    const entry = { currency: s?.currency, balance: s?.balance };
    const _currencies = [entry];
    const _key = `${s?.phoneNumber}:${s?.agentId}`;
    let found = false;
    for (let a of grouped) {
      if (a._key == _key) {
        a._currencies.push(entry);
        found = true;
        break;
      }
    }
    if (!found) {
      grouped.push({ ...s, _key, _currencies });
    }
  }
  return grouped;
};

export const getAgentStatuses = () => [
  'cleared',
  'field-cleared',
  'needs-update',
  'pending',
  'pre-cleared',
  'refused',
];

export const isReviewableStatus = (agent) => {
  switch (agent?.contract?.status) {
    case 'field-cleared':
    case 'needs-update':
    case 'pending':
    case 'refused':
      return true;
    default:
      return false;
  }
};

export const isUpdatableStatus = (agent) => {
  switch (agent?.contract?.status) {
    case 'field-cleared':
    case 'needs-update':
    case 'pending':
      return false;
    default:
      return true;
  }
};

export const getVerifyStatusOptions = (status) => {
  const statusOptions = [
    {
      value: 'field-cleared',
      label: 'Clear: 1st level: KYA is sufficient for compliance check',
    },
    {
      value: 'cleared',
      label: 'Clear: 2nd level: KYA is sufficient for agent to be created',
    },
    {
      value: 'needs-update',
      label: 'Needs update: KYA needs more information to be created',
    },
    {
      value: 'refused',
      label: 'Refuse: KYA has been refused and will not be reviewed again',
    },
  ];
  if (status == 'field-cleared') {
    statusOptions.splice(0, 1);
  }
  return statusOptions;
};

export const getContractVariables = () => [
  ['{agent.name}', "The agent's name"],
  ['{agent.address}', "The agent's address"],
  ['{agent.signature}', "The agent's signature"],
  ['{agent.email}', "The agent's email"],
  [
    '{agent.trading_name}',
    'The business name the agent uses in the field and with customers (not always the name registered on the agent line) from agent discovery form',
  ],
  [
    '{agent.landmark}',
    "The nearest landmark to the agent's place of business from agent discovery form",
  ],
  ['{agent.location}', 'The GPS coordinates from agent discovery form'],
  [
    '{agent.owner_name}',
    'The name of the business owner for this agent business; it will be the same as agent.name if this agent is the owner',
  ],
  [
    '{agent.birthdate}',
    'The birth date of the agent, which should be based on the ID details they submitted during KYA. The format can be DD/MM/YYYY',
  ],
  [
    '{agent.id_type}',
    'The name of the id type selected during KYA e.g., NRC, passport. etc',
  ],
  [
    '{agent.passport}',
    'The passport number for this agent. The agent may not have a passport number if they used another form of ID during KYA.',
  ],
  ['{agent.nationality}', 'The nationality of the agent.'],
  [
    '{agent.nrc}',
    'The National Registration Card ID number. The agent may not have a NRC number if they used another form of ID during KYA.',
  ],
  [
    '{agent.driver_license}',
    'The driver license number for this agent. The agent may not have a driver license number if they used another form of ID.',
  ],
  [
    '{agent.ownership}',
    "The agent's ownership status in the business, namely Owner or Handler",
  ],
  ['{agent.national_id}', "The agent's national ID number"],
  [
    '{signature.aggregator}',
    "The signature of the mobile money aggregator (generally Opareta's partner/client)",
  ],
  ['{witness.name}', "The agent's witness' name"],
  ['{witness.signature}', "The agent's witness' signature"],
  ['{today}', "Today's full date e.g. March 12, 2023"],
  ['{today.date}', "Today's date"],
  ['{today.month}', "Today's month"],
  ['{today.year}', "Today's year"],
];
