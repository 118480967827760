import {
  AppBar,
  Error,
  IS_MULTI_PHONE,
  IS_MULTI_UUID,
  Loading,
  makeUnique,
} from '@/common';
import { DynamicAutoComplete } from '@/common/DynamicAutoComplete';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { agentAssignSave } from './agentAssignFormSlice';
import { agentFetch, resetState } from './agentSlice';
import { getFieldAgent } from './utils';

export const AgentAssignForm = () => {
  // console.log('RXD:AgentAssignForm');
  const dispatch = useDispatch();
  const { uuid } = useParams();

  const { agent, isLoading, error } = useSelector((state) => state.agent);

  const {
    fieldAgentList,
    isLoading: fieldAgentListIsLoading,
    error: fieldAgentListError,
  } = useSelector((state) => state.fieldAgentList);

  const { error: formError, status } = useSelector(
    (state) => state.agentAssignForm
  );

  const commentRef = useRef();
  const [fieldAgent, setFieldAgent] = useState('');
  const [fieldAgents, setFieldAgents] = useState([]);
  const [simNumbers, setSimNumbers] = useState([]);

  useEffect(() => {
    if (uuid && uuid !== agent?.uuid && !isLoading && !error) {
      dispatch(resetState());
      dispatch(agentFetch(uuid));
    }
  }, [dispatch, agent, uuid, isLoading, error]);

  useEffect(() => {
    const newSimNumber = [];
    for (const ats of agent?.agentToSimNumbers ?? []) {
      newSimNumber.push(ats.simNumber);
    }
    setSimNumbers(newSimNumber);
    setFieldAgent(agent?.fieldAgent);
  }, [agent]);

  useEffect(() => {
    const query = new URLSearchParams(
      'pp=1:300&oo=name&isActive=true&ff=fa.uuid,fa.name'
    );
    dispatch(fieldAgentListFetch(query));
  }, []);

  const filterFieldAgents = useCallback(
    (value, pp) => {
      // console.log('RXD:filterFieldAgents', { value, pp });
      const query = new URLSearchParams(
        'oo=name&isActive=true&ff=fa.uuid,fa.name'
      );
      query.set('pp', pp || '1:300');
      if (value) {
        const isMultiPhone = IS_MULTI_PHONE.test(value);
        const isMultiUuid = IS_MULTI_UUID.test(value);
        if (isMultiPhone || isMultiUuid) {
          const label = isMultiPhone ? 'phoneNumber' : 'uuid';
          query.set(label, `in:${value}`);
        } else {
          query.set('name', `contains:${value}`);
        }
      }
      if (!fieldAgentListIsLoading) {
        dispatch(fieldAgentListFetch(query));
      }
    },
    [dispatch, uuid, fieldAgentListIsLoading]
  );

  useEffect(() => {
    const newUuids = {};
    for (const fieldAgent of fieldAgentList) {
      newUuids[fieldAgent.uuid] = fieldAgent;
    }

    const existingUuids = {};
    for (const fieldAgent of fieldAgents) {
      if (newUuids[fieldAgent.uuid]) {
        existingUuids[fieldAgent.uuid] = 1;
      }
    }

    if (Object.keys(existingUuids).length !== Object.keys(newUuids).length) {
      const newFieldAgentsList = [...fieldAgents];
      for (const uuid of Object.keys(newUuids)) {
        if (!existingUuids[uuid]) {
          newFieldAgentsList.push(newUuids[uuid]);
        }
      }
      newFieldAgentsList.sort((a, b) => a?.name?.localeCompare(b?.name));
      setFieldAgents(newFieldAgentsList);
    }
  }, [fieldAgentList]);

  const handleSave = (e) => {
    e.preventDefault();

    dispatch(
      agentAssignSave(uuid, {
        uuid,
        fieldAgent: { uuid: fieldAgent.uuid },
        simNumbers: simNumbers.map((s) => ({
          uuid: s.uuid,
          fieldAgent: { uuid: s.fieldAgent.uuid },
        })),
        comment: commentRef.current.value,
      })
    );
  };

  if (!uuid) {
    return <Navigate to="/agents" />;
  }

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <>
      <AppBar>
        Assign Field Agent for{' '}
        {agent?.uuid === uuid ? agent?.agentName ?? uuid : uuid}
      </AppBar>

      {error && (
        <Error
          error={`Agent: ${error}`}
          onRetry={() => dispatch(agentFetch(uuid))}
        />
      )}

      {formError && <Error error={formError} />}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <Alert severity="info" sx={{ p: 1 }}>
                    {agent?.fieldAgent
                      ? `Current Field Agent is: ${getFieldAgent(agent)}`
                      : 'No Field Agent currently assigned'}
                  </Alert>
                  <Box sx={{ p: 1 }} component="form" onSubmit={handleSave}>
                    <DynamicAutoComplete
                      controlProps={{ sx: { mb: 2 } }}
                      options={makeUnique(fieldAgents, (x) => x?.uuid)}
                      required={true}
                      isOptionEqualToValue={(option, value) =>
                        option?.uuid === value?.uuid
                      }
                      getOptionLabel={(option) => option?.name ?? ''}
                      handleSearch={filterFieldAgents}
                      renderOption={(props, fieldAgent) => (
                        <li {...props} key={fieldAgent?.uuid}>
                          {fieldAgent?.name ?? ''}
                        </li>
                      )}
                      value={fieldAgent}
                      onChange={setFieldAgent}
                      isLoading={fieldAgentListIsLoading}
                      error={fieldAgentListError}
                      renderInput={(value) => (params) => {
                        // console.log('RXD:ExpenseForm:renderInput', { params });
                        return (
                          <TextField
                            {...params}
                            value={value}
                            label="Field Agent"
                          />
                        );
                      }}
                    />

                    {simNumbers.map((simNumber, index) => {
                      return (
                        <Grid
                          container
                          spacing={0}
                          sx={{ pt: 2 }}
                          key={simNumber.uuid}
                        >
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              label={`Phone Number`}
                              value={`${simNumber?.currency ?? ''} ${simNumber.phoneNumber}`}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              label="Agent ID"
                              value={simNumber.agentId ?? ' '}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item md={8} xs={12}>
                            <DynamicAutoComplete
                              options={makeUnique(fieldAgents, (x) => x?.uuid)}
                              isOptionEqualToValue={(option, value) =>
                                option?.uuid === value?.uuid
                              }
                              getOptionLabel={(option) => option?.name ?? ''}
                              handleSearch={filterFieldAgents}
                              renderOption={(props, fieldAgent) => (
                                <li {...props} key={fieldAgent?.uuid}>
                                  {fieldAgent?.name ?? ''}
                                </li>
                              )}
                              value={simNumber.fieldAgent ?? ''}
                              onChange={(fieldAgent) => {
                                const newSimNumbers = [...simNumbers];

                                newSimNumbers[index] = {
                                  ...newSimNumbers[index],
                                  fieldAgent,
                                };
                                setSimNumbers(newSimNumbers);
                              }}
                              isLoading={fieldAgentListIsLoading}
                              required={true}
                              error={fieldAgentListError}
                              renderInput={(value) => (params) => {
                                // console.log('RXD:ExpenseForm:renderInput', { params });
                                return (
                                  <TextField
                                    {...params}
                                    value={value}
                                    label={`Field Agent (for id: ${simNumber.uuid})`}
                                  />
                                );
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}

                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <TextField
                        id="comment"
                        label="Comment"
                        variant="outlined"
                        type="text"
                        inputRef={commentRef}
                        multiline
                        rows={2}
                        sx={{ mt: 2 }}
                      />
                    </FormControl>
                    {status === 'loading' ? (
                      <Loading />
                    ) : (
                      <Button type="submit" variant="contained" sx={{ mb: 1 }}>
                        Save
                      </Button>
                    )}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
