import { getApi, getApiError } from '@/common';
import { makeDownload } from '@/common/api';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reassignmentList: [],
  reassignment: '',
  isLoading: false,
  lastFilter: null,
  error: '',
};

export const reassignmentListSlice = createSlice({
  name: 'reassignmentList',
  initialState: initialState,
  reducers: {
    setReassignments: (state, { payload }) => {
      const { lastFilter, reassignments } = payload;

      state.lastFilter = lastFilter;
      state.isLoading = false;
      state.reassignmentList = reassignments;
    },
    setLoading: (state) => {
      state.error = '';
      state.isLoading = true;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    setUpdatingReassignments: (state, value) => {
      state.error = '';
      state.updatingReassignments = value;
    },
  },
});

export const reassignmentListFetchUsingApi = (filters) => {
  return getApi().get('/api/reassignments', { params: filters });
};

export const reassignmentListFetch = (filters) => (dispatch) => {
  dispatch(setLoading(true));
  reassignmentListFetchUsingApi(filters)
    .then((response) =>
      dispatch(
        setReassignments({
          lastFilter: filters?.toString(),
          reassignments: response.data.data,
        })
      )
    )
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const reassignmentListExport = (filter) => (dispatch) => {
  getApi()
    .get('/api/reassignments/export', { params: filter, responseType: 'blob' })
    .then(makeDownload)
    .catch((err) => dispatch(setError(getApiError(err))));
};

export const clearReassignments = (reassignmentIds) => (dispatch) => {
  const envelope = {
    ids: reassignmentIds,
  };
  dispatch(setUpdatingReassignments(true));
  getApi()
    .post(`/api/reassignments/clear`, envelope)
    .then((response) => {
      dispatch(setUpdatingReassignments(false));
    })
    .catch((err) => {
      dispatch(setUpdatingReassignments(false));
      dispatch(setError(getApiError(err)));
    });
};

export const confirmReassignments = (reassignmentIds) => (dispatch) => {
  const envelope = {
    ids: reassignmentIds,
  };
  dispatch(setUpdatingReassignments(true));
  getApi()
    .post(`/api/reassignments/confirm`, envelope)
    .then((response) => {
      dispatch(setUpdatingReassignments(false));
    })
    .catch((err) => {
      dispatch(setUpdatingReassignments(false));
      dispatch(setError(getApiError(err)));
    });
};

export const {
  setReassignments,
  setError,
  setLoading,
  setUpdatingReassignments,
} = reassignmentListSlice.actions;

export const reassignmentListReducer = reassignmentListSlice.reducer;
