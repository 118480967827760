import {
  Box,
  Button,
  debounce,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReassignments,
  confirmReassignments,
  reassignmentListFetch,
  setUpdatingReassignments,
} from './ReassignmentListSlice';
import { OparetaTable } from '@/common/OparetaTable';
import { useEffect, useState } from 'react';
import { getSortOrder, ICON_MAPPING, useFilters } from '@/common';
import icon from '@/images/marker.svg';
import { Search, Place } from '@mui/icons-material';
import dayjs from 'dayjs';
import {
  BitmapLayer,
  DeckGL,
  FlyToInterpolator,
  IconLayer,
  PolygonLayer,
  TileLayer,
} from 'deck.gl';
import { ConfirmReassignmentsDialog } from './ConfirmReassignmentsDialog';
import { ClearReassignmentsDialog } from './ClearReassignmentsDialog';
import { paginationValue } from '@/common/filter';
import {
  getCurrentFilters,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';
import { hasNewFieldAgent } from './hasNewFieldAgent';

export const ReassignmentsMapView = ({ embedded }) => {
  const dispatch = useDispatch();
  const {
    reassignmentList,
    isLoading,
    error,
    lastFilter,
    updatingReassignments,
  } = useSelector((state) => {
    return state.reassignmentList;
  });

  const [filters, setFilters] = useFilters();
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);

  const [activeReassignment, setActiveReassignment] = useState({});

  const [mapState, setMapState] = useState({
    longitude: 17.8770032,
    latitude: -11.2135241,
    zoom: 5,
  });

  const [confirmReassignmentsDialogOpen, setConfirmReassignmentsDialogOpen] =
    useState(false);

  const [confirmingReassignments, setConfirmingReassignments] = useState(false);

  const [clearReassignmentsDialogOpen, setClearReassignmentsDialogOpen] =
    useState(false);

  const [clearingReassignments, setClearingReassignments] = useState(false);

  const [reassignmentIds, setReassignmentIds] = useState([]);

  const loadCurrentFilters = () => {
    const currentFilters = getCurrentFilters(filters, embedded, [
      'reassignment.uuid',
    ]);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', 'agent.agentName');
    }
    return currentFilters;
  };

  useEffect(() => {
    const currentFilters = loadCurrentFilters();
    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(reassignmentListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [filters]);

  useEffect(() => {
    const currentFilters = loadCurrentFilters();
    dispatch(reassignmentListFetch(currentFilters));
    dispatch(setUpdatingReassignments(false));
  }, []);

  useEffect(() => {
    const processing = updatingReassignments?.payload ?? false;
    if (!processing) {
      if (clearingReassignments) {
        setClearReassignmentsDialogOpen(false);
        setClearingReassignments(false);
        const currentFilters = loadCurrentFilters();
        dispatch(reassignmentListFetch(currentFilters));
        setActiveReassignment({});
      } else if (confirmingReassignments) {
        setConfirmReassignmentsDialogOpen(false);
        setConfirmingReassignments(false);
        const currentFilters = loadCurrentFilters();
        dispatch(reassignmentListFetch(currentFilters));
        setActiveReassignment({});
      }
    }
  }, [updatingReassignments]);

  const w = (callback) => (param) => callback(param.row);

  const columns = [
    {
      headerName: 'Agent Name',
      field: 'agent.agentName',
      valueGetter: w((reassignment) => reassignment.agent?.agentName),
      sortingOrder: getSortOrder(),
      filterable: false,
    },
    {
      headerName: 'Agent Number',
      field: 'agentNumber',
      valueGetter: w(
        (reassignment) => reassignment.agent?.simNumbers[0]?.phoneNumber
      ),
      filterable: false,
    },
  ];

  const STYLES = {
    border: '0.5px solid rgba(0,0,0,0.2)',
    borderRadius: '1rem',
    agentDetails: {
      label: {},
      subtitle: {
        fontSize: '0.7rem',
        color: 'rgba(0,0,0,0.5)',
      },
    },
  };

  const getOldTerritoryName = (reassignment) => {
    let oldTerritoryName = '';
    const noTerritories = reassignment.oldFieldAgent?.territories?.length || 0;
    const names = [];
    for (let i = 0; i < noTerritories; i++) {
      const territory = reassignment.oldFieldAgent.territories[i];
      const name = territory.location.name;
      names.push(name);
    }

    if (names.length > 0) {
      oldTerritoryName = names.join(' | ');
    } else {
      oldTerritoryName = '...';
    }

    return oldTerritoryName;
  };

  const getTimeframe = (reassignment) => {
    let timeInWeeks = '';
    let timeframe = '';
    try {
      const today = dayjs();
      const addedAt = dayjs(reassignment?.addedToNewTerritoryAt);
      timeInWeeks = today.diff(addedAt, 'week');
    } catch (err) {
      console.log(err);
    }

    if (timeInWeeks == '0') {
      timeframe = `Less than 1 week`;
    } else {
      timeframe = `${timeInWeeks} Weeks`;
    }

    return timeframe;
  };

  const newLocationLayer = new IconLayer({
    id: 'newLocationLayer',
    data: [activeReassignment],
    getColor: (reassignment) => [33, 206, 153],
    getIcon: (reassignment) => 'marker',
    getPosition: (reassignment) => {
      const coords = reassignment.newLocation?.coordinates || [];
      return coords;
    },
    getSize: (d) => 40,
    iconAtlas: icon,
    iconMapping: ICON_MAPPING,
    pickable: true,
  });

  const newTerritoryLayer = new PolygonLayer({
    id: 'newTerritoryLayer',
    data: [activeReassignment],
    getPolygon: (reassignment) => {
      const bounds = reassignment.newTerritory?.bounds?.coordinates[0] || [];
      return bounds;
    },
    getElevation: (reassignment) => 1,
    getFillColor: (reassignment) => [33, 206, 153],
    getLineColor: [20, 20, 20],
    getLineWidth: 20,
    lineWidthMinPixels: 1,
    pickable: true,
    opacity: 0.2,
  });

  const oldTerritoryLayer = new PolygonLayer({
    id: 'oldTerritoryLayer',
    data: activeReassignment.oldFieldAgent?.territories,
    getPolygon: (territory) => {
      const bounds = territory.location?.bounds?.coordinates[0] || [];
      return bounds;
    },
    getElevation: (territory) => 1,
    getFillColor: (territory) => [75, 169, 255],
    getLineColor: [20, 20, 20],
    getLineWidth: 20,
    lineWidthMinPixels: 1,
    pickable: true,
    opacity: 0.2,
  });

  const flyToLocation = ({ coords1, coords2 }) => {
    /*
        This is needed to enable flying to a territory when it was already recently flown to
      */
    let coords = coords1;
    if (mapState.longitude == coords1[0]) {
      coords = coords2;
    }

    const newFlyPoint = {
      longitude: coords[0],
      latitude: coords[1],
      zoom: 10,
      transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
      transitionDuration: 'auto',
    };

    setMapState(newFlyPoint);
  };

  const flyToNewTerritory = () => {
    const coords1 = activeReassignment.newTerritory?.bounds?.coordinates[0][0];

    const coords2 = activeReassignment.newTerritory?.bounds?.coordinates[0][2];

    flyToLocation({ coords1, coords2 });
  };

  const flyToOldTerritory = () => {
    const coords1 =
      activeReassignment.oldFieldAgent?.territories[0]?.location?.bounds
        ?.coordinates[0][0];

    const coords2 =
      activeReassignment.oldFieldAgent?.territories[0]?.location?.bounds
        ?.coordinates[0][1];

    flyToLocation({ coords1, coords2 });
  };

  const mapLayer = new TileLayer({
    data: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/512/{z}/{x}/{y}?access_token=${
      import.meta.env.VITE_MAP_BOX_TOKEN
    }`,
    minZoom: 0,
    maxZoom: 21,
    tileSize: 256,

    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      });
    },
  });

  const startReassign = () => {
    setConfirmReassignmentsDialogOpen(true);
    setConfirmingReassignments(false);
  };

  const startClear = () => {
    setClearReassignmentsDialogOpen(true);
    setClearingReassignments(false);
  };

  const cancelReassign = () => {
    setConfirmReassignmentsDialogOpen(false);
  };

  const confirmReassign = () => {
    setConfirmingReassignments(true);
    dispatch(confirmReassignments(reassignmentIds));
  };

  const cancelClear = () => {
    setClearReassignmentsDialogOpen(false);
  };

  const confirmClear = () => {
    setClearingReassignments(true);
    dispatch(clearReassignments(reassignmentIds));
  };

  const search = debounce((e) => {
    /*
      Determine if we should do an agent name search or phonenumber search
      - we only do a agent name search if all the characters are word characters
    */
    const searchQuery = e.target.value;
    const nameCheck = /^([a-zA-Z]+\s*)+$/;
    const shouldDoAgentNameSearch = nameCheck.test(searchQuery);
    const newFilters = new URLSearchParams(filters);
    let field = '';
    const operation = `contains:${searchQuery}`;
    if (shouldDoAgentNameSearch) {
      //Do name search
      field = 'agent.agentName';
    } else {
      //Do phone number search
      field = 'agentNumber';
    }
    if (!!searchQuery) {
      newFilters.set(field, `${operation}`);
    } else {
      newFilters.delete('agent.agentName');
      newFilters.delete('agentNumber');
    }
    setFilters(newFilters);
  }, 300);

  return (
    <>
      <ConfirmReassignmentsDialog
        open={confirmReassignmentsDialogOpen}
        confirming={confirmingReassignments}
        onClose={cancelReassign}
        onConfirm={confirmReassign}
      />

      <ClearReassignmentsDialog
        open={clearReassignmentsDialogOpen}
        clearing={clearingReassignments}
        onClose={cancelClear}
        onConfirm={confirmClear}
      />

      <Grid
        container
        sx={{
          minHeight: 'calc(65vh)',
          border: STYLES.border,
          borderRadius: STYLES.borderRadius,
        }}
      >
        <Grid
          item
          xs={3}
          paddingLeft={2}
          paddingRight={2}
          paddingTop={2}
          sx={{
            backgroundColor: 'white',
            borderTopLeftRadius: STYLES.borderRadius,
            borderBottomLeftRadius: STYLES.borderRadius,
            borderRight: STYLES.border,
          }}
        >
          <FormControl
            variant="outlined"
            sx={{ width: '100%', marginBottom: '1rem' }}
          >
            <OutlinedInput
              id="reassignment-search"
              onChange={search}
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'search',
              }}
              label="Search"
              sx={{
                borderRadius: STYLES.borderRadius,
              }}
            />
            <InputLabel htmlFor="reassignment-search">Search</InputLabel>
            <FormHelperText
              size="small"
              sx={{ color: 'rgba(0,0,0,0.3)', fontWeight: '1rem' }}
            >
              Search by agent phone number or name
            </FormHelperText>
          </FormControl>
          <OparetaTable
            showToolbar={false}
            hideBorder={true}
            getRowId={(reassignment) => reassignment.uuid}
            rows={reassignmentList ?? []}
            columns={columns}
            onExportClick={() => {
              console.log(`Exporting data`);
            }}
            onCellClick={(param, e) => {
              const reassignment = param.row;
              setActiveReassignment(reassignment);

              const coords1 = reassignment.newLocation?.coordinates || [];
              const coords2 = reassignment.newLocation?.coordinates || [];

              if (coords1.length == 2 && coords2.length == 2) {
                flyToLocation({ coords1, coords2 });
              }
            }}
            loading={isLoading}
            page={serverPage - 1}
            onFilterModelChange={getOnFilterModelChange(
              filters,
              setFilters,
              serverPerPage
            )}
            onSortModelChange={getOnSortModelChange(
              filters,
              setFilters,
              serverPerPage
            )}
            onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
            onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          />
        </Grid>
        <Grid container xs={9}>
          <Grid item xs={8} padding={2}>
            <Box
              sx={{
                border: STYLES.border,
                borderRadius: STYLES.borderRadius,
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <DeckGL
                mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
                initialViewState={mapState}
                controller={true}
                getTooltip={({ object }) => object && object.name}
                layers={[
                  mapLayer,
                  oldTerritoryLayer,
                  newTerritoryLayer,
                  newLocationLayer,
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={4} padding={2}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: STYLES.borderRadius,
                width: '100%',
                height: '100%',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                paddingTop: '1rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <Typography sx={{ fontSize: '1.2rem', fontWeight: '700' }}>
                Agent Details
              </Typography>

              <Box display="flex" alignItems="end" gap="1.5rem">
                <Box sx={{ flex: '1' }}>
                  <Typography>
                    {activeReassignment?.agent?.agentName || '...'}
                  </Typography>
                  <Typography sx={STYLES.agentDetails.subtitle}>
                    Agent Name
                  </Typography>
                </Box>
                <Box sx={{ flex: '1' }}>
                  <Typography>
                    {(activeReassignment?.agent?.simNumbers || [])[0]
                      ?.phoneNumber || '...'}
                  </Typography>
                  <Typography sx={STYLES.agentDetails.subtitle}>
                    Agent Number
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography>
                  {activeReassignment?.oldFieldAgent?.name || '...'}
                </Typography>
                <Typography sx={STYLES.agentDetails.subtitle}>
                  Old Field Agent
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Place
                    onClick={flyToOldTerritory}
                    sx={{ color: 'rgba(75, 169, 255, 1)', cursor: 'pointer' }}
                  />
                  <Typography>
                    {getOldTerritoryName(activeReassignment)}
                  </Typography>
                </Box>

                <Typography sx={STYLES.agentDetails.subtitle}>
                  Old Territory
                </Typography>
              </Box>

              <Box>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Place
                    onClick={flyToNewTerritory}
                    sx={{ color: 'rgba(33, 206, 153, 1)', cursor: 'pointer' }}
                  />
                  <Typography>
                    {activeReassignment?.newTerritory?.name || '...'}
                  </Typography>
                </Box>

                <Typography sx={STYLES.agentDetails.subtitle}>
                  New Territory
                </Typography>
              </Box>

              <Box>
                <Typography>{getTimeframe(activeReassignment)}</Typography>
                <Typography sx={STYLES.agentDetails.subtitle}>
                  Time in new territory
                </Typography>
              </Box>

              <Box
                component="fieldset"
                sx={{
                  fontWeight: '400',
                  border: STYLES.border,
                  borderRadius: STYLES.borderRadius,
                }}
              >
                <legend style={STYLES.agentDetails.subtitle}>
                  New Field Agent
                </legend>

                {!activeReassignment?.uuid && <>...</>}

                {!!activeReassignment?.uuid &&
                  hasNewFieldAgent(activeReassignment) && (
                    <>
                      {(activeReassignment?.newTerritory?.territories || [])[0]
                        ?.fieldAgent?.name || '...'}
                    </>
                  )}

                {!!activeReassignment?.uuid &&
                  !hasNewFieldAgent(activeReassignment) && (
                    <Typography color="error" sx={{ fontSize: '0.7rem' }}>
                      No field agent assigned to territory
                    </Typography>
                  )}
              </Box>

              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    ':hover': { backgroundColor: 'rgba(0,0,0,0.5)' },
                  }}
                  onClick={() => {
                    if (!!activeReassignment && !!activeReassignment.uuid) {
                      /*
                      NB : because we are only updating one record
                      we need to replace the array with a new one
                    */
                      const newReassignmentIds = [activeReassignment.uuid];
                      setReassignmentIds(newReassignmentIds);
                      startClear();
                    }
                  }}
                >
                  Clear
                </Button>

                {hasNewFieldAgent(activeReassignment) && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (!!activeReassignment && !!activeReassignment.uuid) {
                        /*
                      NB : because we are only updating one record
                      we need to replace the array with a new one
                    */
                        const newReassignmentIds = [activeReassignment.uuid];
                        setReassignmentIds(newReassignmentIds);
                        startReassign();
                      }
                    }}
                  >
                    Reassign
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
