import { hasAnyRoles } from '@/auth/helpers';
import {
  AppBar,
  Error,
  Info as InfoBase,
  Loading,
  dateFormat,
  getExternalIdLabel,
} from '@/common';
import { ForFeature } from '@/common/AuthorizedPage';
import { FEATURES } from '@/common/features';
import { captureEvent } from '@/firebase';
import {
  editFieldAgentThreshold,
  editThreshold,
  fieldAgentThresholdFetch,
  setSubmitting,
  thresholdFetch,
} from '@/float-cash/floatCashThresholdDetailsSlice';
import { amountToNumberOrZero, isNullOrEmpty } from '@/float-cash/util';
import closeIcon from '@/images/icons8_close.png';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

export const FloatCashThresholdDetail = ({ embedded }) => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [editThresholdDialogOpen, setEditThresholdDialogOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { transaction, isLoading, isSubmitting, error } = useSelector(
    (state) => state.floatCashThreshold
  );
  const { type } = embedded;

  useEffect(() => {
    if (!isSubmitting && editThresholdDialogOpen) {
      handleEditThresholdDialogClose();
    }
  }, [isSubmitting]);

  useEffect(() => {
    setEditThresholdDialogOpen(false);
    fetchThresholdDetails();
  }, []);

  useEffect(() => {
    if (!editThresholdDialogOpen) {
      console.log('>>> Setting submitting. Open? ', editThresholdDialogOpen);
      dispatch(setSubmitting(false));
    }
  }, [editThresholdDialogOpen]);

  const fetchThresholdDetails = () => {
    dispatch(
      type === 'fieldAgent'
        ? fieldAgentThresholdFetch(uuid)
        : thresholdFetch(uuid)
    );
  };

  const handleEditThresholdDialogClose = () =>
    setEditThresholdDialogOpen(false);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <EditThresholdModal
            type={type}
            isOpen={editThresholdDialogOpen}
            transaction={transaction}
            handleEditThresholdDialogClose={handleEditThresholdDialogClose}
          />
          <AppBar
            actions={
              hasAnyRoles(user.role, 'RECONCILIATION_ADMIN') ? (
                <>
                  <Button
                    disableElevation
                    sx={{ textTransform: 'none' }}
                    variant="contained"
                    border="2px solid"
                    size="medium"
                    component={RouterLink}
                    onClick={() => setEditThresholdDialogOpen(true)}
                  >
                    Edit Threshold
                  </Button>
                </>
              ) : null
            }
          >
            {'View threshold'}: {uuid}
          </AppBar>
          {error && (
            <Error
              error={error}
              onRetry={() => dispatch(thresholdFetch(uuid))}
            />
          )}
          <TransactionDetail transaction={transaction} />
        </>
      )}
    </>
  );
};

const TransactionDetail = (props) => {
  const { transaction, elevated } = props;
  return (
    <>
      {transaction ? (
        <Card
          elevation={
            elevated === null || elevated === undefined || elevated === true
              ? 1
              : 0
          }
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!isNullOrEmpty(transaction?.fieldAgent?.name) && (
                  <Info label="TDR Name">{transaction?.fieldAgent?.name}</Info>
                )}
                <ForFeature feature={FEATURES.AGENT_EXTERNAL_ID}>
                  <Info label={getExternalIdLabel()}>
                    {amountToNumberOrZero(transaction?.agent?.externalId)}
                  </Info>
                </ForFeature>
                {!isNullOrEmpty(transaction?.dailyDebtThreshold) ? (
                  <Info label="Debt Threshold">
                    {transaction.currency}{' '}
                    {amountToNumberOrZero(transaction?.dailyDebtThreshold)}
                  </Info>
                ) : (
                  <></>
                )}
                {!isNullOrEmpty(transaction?.cashThreshold) && (
                  <Info label="Cash in hand Threshold">
                    {transaction.currency}{' '}
                    {amountToNumberOrZero(transaction?.cashThreshold)}
                  </Info>
                )}

                {!isNullOrEmpty(transaction?.dailyFloatRequestLimit) ? (
                  <Info label="Float request threshold">
                    {transaction.currency}{' '}
                    {amountToNumberOrZero(transaction?.dailyFloatRequestLimit)}
                  </Info>
                ) : (
                  <></>
                )}
                {transaction?.autoApproveFloatRequest != null && (
                  <Info label="Automatically approve requests below threshold">
                    {transaction?.autoApproveFloatRequest === true
                      ? 'On'
                      : 'Off'}
                  </Info>
                )}

                {transaction?.comment ? (
                  <Info label="Comments:">{transaction?.comment}</Info>
                ) : (
                  <></>
                )}
                {!!transaction?.createdAt ? (
                  <Info label="Date:">
                    {dateFormat(transaction?.createdAt)}
                  </Info>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 2 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const EditThresholdModal = (props) => {
  const { type, isOpen, transaction, handleEditThresholdDialogClose } = props;
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [canSave, setCanSave] = useState(false);
  const [autoApproveRequests, setAutoApproveRequests] = useState(false);
  const initialFormState = {
    dailyDebtThreshold: transaction?.dailyDebtThreshold,
    dailyFloatRequestLimit: transaction?.dailyFloatRequestLimit,
    comment: transaction?.comment,
    cashThreshold: transaction?.cashThreshold,
    autoApproveFloatRequest: transaction?.autoApproveFloatRequest,
  };
  const [formData, setFormData] = useState(initialFormState);
  const { isSubmitting } = useSelector((state) => state.floatCashThreshold);

  useEffect(() => {
    setCanSave(
      amountToNumberOrZero(formData.dailyDebtThreshold) > 0 &&
        (amountToNumberOrZero(formData.dailyFloatRequestLimit) > 0 ||
          amountToNumberOrZero(formData.cashThreshold) > 0)
    );
  }, [formData, autoApproveRequests]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleOnChange = (title) => (e) => {
    let value = e.target.value;
    if (
      title === 'dailyDebtThreshold' ||
      title === 'dailyFloatRequestLimit' ||
      title === 'cashThreshold'
    ) {
      setFormData({
        ...formData,
        [title]: amountToNumberOrZero(value),
      });
    } else {
      setFormData({ ...formData, [title]: value });
    }
  };
  const handleSubmit = () => {
    if (type === 'fieldAgent') {
      dispatch(editFieldAgentThreshold(uuid, formData));
    } else {
      formData.autoApproveFloatRequest = autoApproveRequests;
      dispatch(editThreshold(uuid, formData));
    }

    captureEvent(`${type.toUpperCase()}_THRESHOLD_FORM_UPDATE`, {});
  };

  const resetForm = () => {
    setFormData(initialFormState);
  };

  return type === 'fieldAgent' ? (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button
              variant="text"
              onClick={() => handleEditThresholdDialogClose()}
            >
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Stack direction="column">
              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="text"
                label="Field Agent"
                required
                disabled
                value={transaction?.fieldAgent?.name}
              />

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Debt threshold amount"
                required
                onChange={handleOnChange('dailyDebtThreshold')}
                placeholder={transaction?.dailyDebtThreshold}
              />

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Cash in hand threshold"
                required
                onChange={handleOnChange('cashThreshold')}
                placeholder={transaction?.dailyFloatRequestLimit}
              />

              <TextField
                autoFocus
                margin="dense"
                id="comments"
                label="Comments"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={3}
                sx={{ mt: 3 }}
                onChange={handleOnChange('comment')}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          disabled={!canSave || isSubmitting}
          onClick={handleSubmit}
        >
          <span>Save</span>{' '}
          {isSubmitting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <></>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog open={isOpen} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack direction="row" flex justifyContent="end">
          <Box>
            <Button
              variant="text"
              onClick={() => handleEditThresholdDialogClose()}
            >
              <img src={closeIcon} alt="" width="30px" height="30px" />
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Stack direction="column">
              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="text"
                label="Agent"
                required
                disabled
                value={transaction?.agent?.agentName}
              />

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Debt threshold amount"
                required
                onChange={handleOnChange('dailyDebtThreshold')}
                placeholder={transaction?.dailyDebtThreshold}
              />

              <TextField
                sx={{ width: '100%', mt: 3 }}
                type="number"
                label="Float request threshold amount"
                required
                onChange={handleOnChange('dailyFloatRequestLimit')}
                placeholder={transaction?.dailyFloatRequestLimit}
              />

              <FormControlLabel
                control={<Switch checked={autoApproveRequests} />}
                label="Auto approval of requests below threshold"
                onChange={(e) => {
                  setAutoApproveRequests(!autoApproveRequests);
                }}
              />

              <TextField
                autoFocus
                margin="dense"
                id="comments"
                label="Comments"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={3}
                sx={{ mt: 3 }}
                onChange={handleOnChange('comment')}
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          variant="contained"
          disabled={!canSave || isSubmitting}
          onClick={handleSubmit}
        >
          <span>Save</span>{' '}
          {isSubmitting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <></>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
