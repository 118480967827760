export const hasNewFieldAgent = (reassignment) => {
  let result = false;
  if (reassignment?.newTerritory?.territories?.length > 0) {
    const territory = reassignment.newTerritory.territories[0];
    if (!!territory?.fieldAgent?.name) {
      result = true;
    }
  }
  return result;
};
