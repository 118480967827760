import DangerousIcon from '@mui/icons-material/Dangerous';
import VerifiedIcon from '@mui/icons-material/Verified';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import MuiTooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  agentVerifyExternalId,
  agentVerify,
  agentFetch,
  setFormError,
} from './agentSlice';

import { hasAnyRoles } from '@/auth/helpers';
import {
  AppBar,
  Bubble,
  Error,
  Info as InfoBase,
  Loading,
  TabPanel,
  dateFormat,
  getExternalIdLabel,
  numberFormat,
  useFilters,
} from '@/common';
import { ForFeature } from '@/common/AuthorizedPage';
import { BooleanBubble } from '@/common/Bubble';
import { EmbeddedChart } from '@/common/EmbeddedChart';
import { a11yProps } from '@/common/TabPanel';
import { FEATURES, hasFeature } from '@/common/features';
import { DASHBOARD_AGENT_DETAIL } from '@/dashboardConfiguration/utils';
import { ExpenseList } from '@/expense';
import { fieldAgentListFetch } from '@/fieldAgent/fieldAgentListSlice';
import { captureEvent, logButtonEvents } from '@/firebase';
import { LoanList, LoanPaymentList, LoanRequestList } from '@/loan';
import { TransactionList } from '@/transaction';
import { agentRegenerateContract } from './agentContractFormSlice';
import { agentSave } from './agentFormSlice';
import { pinSave } from './pinFormSlice';
import { simNumberSave } from './simNumberFormSlice';
import {
  createdAt,
  formattedCount,
  formattedVolume,
  getAgentStatus,
  getAgentStatusBackground,
  getAgentStatusTextColor,
  getBirthday,
  getComplianceStatusBackground,
  getCurrencyGrouped,
  getExposure,
  getFieldAgent,
  getKyaFieldAgent,
  getKycBackgroundColor,
  getKycProgress,
  getSimCategory,
  getSimNumberCategories,
  getTradingName,
  getVerifyStatusOptions,
  isExposed,
  isReviewableStatus,
  isUpdatableStatus,
  lastActiveAt,
  shouldSetSimNumberAgentId,
  shouldShowExternalIdOnVerify,
  updatedAt,
  verifiedAt,
} from './utils';

export const AgentDetail = () => {
  // console.log('RXD:AgentDetail');
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const { agent, isLoading, error } = useSelector((state) => state.agent);
  const { user } = useSelector((state) => state.auth);

  const [simNumber, setSimNumber] = useState('');
  const [complianceFormOpen, setComplianceFormOpen] = useState(false);
  const [verifyFormOpen, setVerifyFormOpen] = useState(false);
  const [simNumberFormOpen, setSimNumberFormOpen] = useState(false);
  const [pinFormOpen, setPinFormOpen] = useState(false);
  const [updateKyaFormOpen, setUpdateKyaFormOpen] = useState(false);

  // console.log('RXD:AgentDetail', agent, isLoading, error);
  useEffect(() => {
    if (
      (uuid !== agent?.uuid && !isLoading && !error) ||
      (agent && !('simNumbers' in agent) && !isLoading)
    ) {
      dispatch(agentFetch(uuid));
    }
  }, [dispatch, uuid, agent, isLoading, error]);

  const [filters, setFilters] = useFilters();
  const handleTabChange = (event, index) => {
    setFilters(new URLSearchParams(`tab=${index}`));
    captureEvent('TAB_CHANGE', {
      index,
      tab: event?.target?.innerText || '',
      user,
      page: 'agent-detail',
    });
  };
  let tab = filters.get('tab');
  tab = tab === null ? 0 : +tab;

  const [fullPhotoUrl, setFullPhotoUrl] = useState('');

  const handleVerifyAgent = () => {
    setVerifyFormOpen(true);
    logButtonEvents('VERIFY_KYC_AGENT');
  };

  const handleComplianceAgent = () => {
    setComplianceFormOpen(true);
    logButtonEvents('VERIFY_KYA_AGENT');
  };

  const handleUpdateKyaOpen = useCallback(
    (e) => setUpdateKyaFormOpen(true),
    [setUpdateKyaFormOpen]
  );

  const handleUpdateKyaClose = useCallback(
    (e) => setUpdateKyaFormOpen(false),
    [setUpdateKyaFormOpen]
  );

  return (
    <>
      <AppBar
        actions={
          !!agent && (
            <>
              {hasAnyRoles(user.role, 'COMPLIANCE_MANAGER') && (
                <ForFeature feature={FEATURES.AGENT_COMPLIANCE}>
                  {isUpdatableStatus(agent) && (
                    <Button variant="contained" onClick={handleUpdateKyaOpen}>
                      Update KYA
                    </Button>
                  )}
                  {isReviewableStatus(agent) && (
                    <Button variant="contained" onClick={handleComplianceAgent}>
                      Verify KYA
                    </Button>
                  )}
                </ForFeature>
              )}
              {hasAnyRoles(user.role, 'FIELD_AGENT_MANAGER') && (
                <ForFeature feature={FEATURES.FIELD_AGENT}>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/agents/${uuid}/assign`}
                    onClick={() => logButtonEvents('ASSIGN_AGENT')}
                  >
                    Assign Field Agent
                  </Button>
                </ForFeature>
              )}
              {hasAnyRoles(user.role, 'AGENT_MANAGER') && (
                <>
                  <ForFeature feature={FEATURES.AGENT_PIN}>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        setPinFormOpen(true);
                        logButtonEvents('RESET_PIN_AGENT');
                      }}
                    >
                      Reset PIN
                    </Button>
                  </ForFeature>
                  <ForFeature feature={FEATURES.AGENT_VERIFICATION}>
                    {!agent.isVerified && (
                      <Button variant="contained" onClick={handleVerifyAgent}>
                        Verify KYA
                      </Button>
                    )}
                  </ForFeature>
                </>
              )}
              {hasAnyRoles(user.role, 'LOAN_VIEWER') && (
                <ForFeature feature={FEATURES.LOAN}>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/loans/requests/add?agent=${encodeURI(
                      JSON.stringify({
                        uuid,
                        agentName: agent.agentName,
                      })
                    )}`}
                    onClick={() => logButtonEvents('MAKE_LOAN_OFFICER_AGENT')}
                  >
                    Make Loan Offer
                  </Button>
                </ForFeature>
              )}
              {hasAnyRoles(user.role, 'AGENT_MANAGER') && (
                <ForFeature feature={FEATURES.AGENT_EXPENSE}>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/agents/${uuid}/expenses/add`}
                    onClick={() => logButtonEvents('ADD_EXPENSE_AGENT')}
                  >
                    Add Expense
                  </Button>
                </ForFeature>
              )}
            </>
          )
        }
      >
        Agent: {agent?.uuid === uuid ? agent?.agentName : uuid}
      </AppBar>

      <FullPicture url={fullPhotoUrl} handleClose={() => setFullPhotoUrl('')} />

      <UpdateKyaForm
        agent={agent}
        open={updateKyaFormOpen}
        handleClose={handleUpdateKyaClose}
      />

      <ComplianceForm
        agent={agent}
        open={complianceFormOpen}
        handleClose={(e) => setComplianceFormOpen(false)}
      />

      <VerifyForm
        agent={agent}
        open={verifyFormOpen}
        handleClose={(e) => setVerifyFormOpen(false)}
      />

      <SimNumberForm
        agent={agent}
        simNumber={simNumber}
        open={simNumberFormOpen}
        handleClose={(e) => setSimNumberFormOpen(false)}
      />

      <PinForm
        agent={agent}
        open={pinFormOpen}
        handleClose={(e) => setPinFormOpen(false)}
      />

      {error ? (
        <Error error={error} />
      ) : !agent || isLoading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            sx={{ background: 'white' }}
            aria-label="agent detail tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {hasFeature(FEATURES.AGENT) && (
              <Tab label="Overview" value={0} {...a11yProps(0)} />
            )}
            {hasFeature(FEATURES.TRANSACTION) && (
              <Tab label="Transactions" value={1} {...a11yProps(1)} />
            )}
            {hasFeature(FEATURES.LOAN) &&
              hasAnyRoles(user.role, 'LOAN_VIEWER') && (
                <Tab label="Loan Requests" value={2} {...a11yProps(2)} />
              )}
            {hasFeature(FEATURES.LOAN) &&
              hasAnyRoles(user.role, 'LOAN_VIEWER') && (
                <Tab label="Loans Disbursed" value={3} {...a11yProps(3)} />
              )}
            {hasFeature(FEATURES.LOAN) &&
              hasAnyRoles(user.role, 'LOAN_VIEWER') && (
                <Tab label="Loan Payments" value={4} {...a11yProps(4)} />
              )}
            {hasFeature(FEATURES.FIELD_AGENT) && (
              <Tab label="Field Agent History" value={5} {...a11yProps(5)} />
            )}
            {hasFeature(FEATURES.REPORT) && (
              <Tab label="Transaction Trends" value={6} {...a11yProps(6)} />
            )}
            {hasFeature(FEATURES.AGENT_COMPLIANCE) && (
              <Tab label="Agent Contract" value={7} {...a11yProps(7)} />
            )}
            {hasFeature(FEATURES.AGENT_COMPLIANCE) && (
              <Tab label="Agent Application" value={9} {...a11yProps(9)} />
            )}
            {hasFeature(FEATURES.AGENT_EXPENSE) &&
              hasAnyRoles(user.role, 'AGENT_MANAGER') && (
                <Tab label="Expenses" value={8} {...a11yProps(8)} />
              )}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <AgentOverview
              agent={agent}
              openFullPhoto={(url) => setFullPhotoUrl(url)}
              openSimNumberForm={(simNumber) => {
                setSimNumber(simNumber);
                setSimNumberFormOpen(true);
              }}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box sx={{ mt: 1 }}>
              <TransactionList embedded={{ agentUuid: agent.uuid }} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Box sx={{ mt: 1 }}>
              <LoanRequestList embedded={{ 'a.uuid': agent.uuid }} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Box sx={{ mt: 1 }}>
              <LoanList embedded={{ 'a.uuid': agent.uuid }} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <Box sx={{ mt: 1 }}>
              <LoanPaymentList embedded={{ 'a.uuid': agent.uuid }} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Box sx={{ mt: 1 }}>
              <FieldAgentHistory agent={agent} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={6}>
            <Box sx={{ mt: 1 }}>
              <TransactionTrends agent={agent} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={7}>
            <Box sx={{ mt: 1 }}>
              <AgentContract agent={agent} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={8}>
            <Box sx={{ mt: 1 }}>
              <ExpenseList mode="agents" embedded={{ 'a.uuid': agent.uuid }} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={9}>
            <Box sx={{ mt: 1 }}>
              <AgentApplication agent={agent} />
            </Box>
          </TabPanel>
        </>
      )}
    </>
  );
};

const Info = ({ label, children, border }) => (
  <InfoBase sx={{ py: 1 }} label={label} border={border ?? true}>
    {children}
  </InfoBase>
);

const FullPicture = ({ url, handleClose }) => (
  <Modal
    open={Boolean(url)}
    onClose={handleClose}
    onClick={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Grid
      container
      spacing={0}
      direction="column"
      sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        component="img"
        sx={{ maxWidth: '100%', maxHeight: '100%' }}
        src={url}
        alt={url}
      />
    </Grid>
  </Modal>
);

const AgentOverview = ({ agent, openFullPhoto, openSimNumberForm }) => {
  const LocationDetail = lazy(() => import('./AgentDetailLocationDetail'));
  const { user } = useSelector((state) => state.auth);

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item md={3} xs={12}>
        <Suspense fallback={<Loading />}>
          <LocationDetail agent={agent} openFullPhoto={openFullPhoto} />
        </Suspense>
        <AgentContact
          user={user}
          agent={agent}
          openSimNumberForm={openSimNumberForm}
        />
      </Grid>
      <Grid item md={9} xs={12}>
        <MainStats agent={agent} />
        <AgentKyc agent={agent} openFullPhoto={openFullPhoto} />
      </Grid>
    </Grid>
  );
};

const MainStats = ({ agent }) => (
  <Card sx={{ mb: 2 }}>
    <Grid container spacing={2} sx={{ px: 2, pb: 1 }}>
      <Grid item md={3} xs={12}>
        <Info label="Transactions" border={false}>
          {formattedCount(agent)}
        </Info>
      </Grid>
      <Grid item md={3} xs={12}>
        <Info label="Transaction Volume" border={false}>
          {formattedVolume(agent)}
        </Info>
      </Grid>
      <Grid item md={3} xs={12}>
        <Info label="Field Agent" border={false}>
          {agent.fieldAgent?.uuid ? (
            <Link
              to={`/field-agents/${agent.fieldAgent?.uuid}`}
              component={RouterLink}
            >
              {getFieldAgent(agent)}
            </Link>
          ) : (
            '-'
          )}
        </Info>
      </Grid>
      <Grid item md={3} xs={12}>
        <Info label="Last Active" border={false}>
          {lastActiveAt(agent)}
        </Info>
      </Grid>
    </Grid>
  </Card>
);

const AgentKyc = ({ agent, openFullPhoto }) =>
  agent && (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Info label="UUID" border={true}>
              {agent.uuid}
            </Info>
            {hasFeature(FEATURES.AGENT_EXTERNAL_ID) && (
              <Info label={getExternalIdLabel()} border={true}>
                {agent?.externalId ?? '-'}
              </Info>
            )}
            <ForFeature feature={FEATURES.AGENT_VERIFICATION}>
              <Info label="Is Verified" border={true}>
                <BooleanBubble value={agent?.isVerified} />
              </Info>
              <Info label="KYC" border={true}>
                <Bubble
                  label={getKycProgress(agent)}
                  background={getKycBackgroundColor(agent)}
                  color={'black'}
                  borderRadius="0"
                  fontWeight="normal"
                />
              </Info>
            </ForFeature>
            <ForFeature feature={FEATURES.AGENT_COMPLIANCE}>
              <Info label="Compliance Status">
                <Bubble
                  label={getAgentStatus(agent)}
                  background={getAgentStatusBackground(agent)}
                  color={getAgentStatusTextColor(agent)}
                />
              </Info>
              <Info label="Compliance Comment" border={true}>
                {agent?.contract?.comment ?? '-'}
              </Info>
              <Info label="Is Active" border={true}>
                <BooleanBubble value={agent?.isVerified} />
              </Info>
            </ForFeature>
            <Info label="Gender">{agent.gender ?? '-'}</Info>
            <Info label="Birthday">{getBirthday(agent)}</Info>
            <Info label="National ID">{agent.nationalId}</Info>
            <Info label="Card Number">{agent.cardNumber ?? '-'}</Info>
            <Info label="Nationality">{agent.nationality ?? '-'}</Info>
            <ForFeature feature={FEATURES.AGENT_VERIFICATION}>
              <Info label="Address">{agent.address ?? '-'}</Info>
              <Info
                label={`Next of Kin: (${agent.nextOfKinRelationship ?? '-'})`}
              >
                {agent.nextOfKinFullName ?? ''}{' '}
                {agent?.nextOfKinPhoneNumber && (
                  <Box>
                    <Link href={`tel://${agent.nextOfKinPhoneNumber}`}>
                      {agent.nextOfKinPhoneNumber}
                    </Link>
                  </Box>
                )}
              </Info>
            </ForFeature>
            <ForFeature feature={FEATURES.AGENT_COMPLIANCE}>
              <Info label="Work Address">{agent.address ?? '-'}</Info>
              <Info
                label={`Emergency contact: (${
                  agent.nextOfKinRelationship ?? '-'
                })`}
              >
                {agent.nextOfKinFullName ?? ''}{' '}
                {agent?.nextOfKinPhoneNumber && (
                  <Box>
                    <Link href={`tel://${agent.nextOfKinPhoneNumber}`}>
                      {agent.nextOfKinPhoneNumber}
                    </Link>
                  </Box>
                )}
              </Info>
            </ForFeature>
          </Grid>
          <Grid item md={6} xs={12}>
            <Info label="KYA Field Agent">
              {getKyaFieldAgent(agent) ?? '-'}
            </Info>
            <ForFeature feature={FEATURES.AGENT_VERIFICATION}>
              <Info label="Business">{agent.businessType ?? '-'}</Info>
              <Info label="Ownership">{agent.ownerType ?? '-'}</Info>
              <Info label="Is Primary Income">
                <BooleanBubble value={agent?.isPrimaryIncome} />
              </Info>
              <Info label="Location">{agent.location ?? '-'}</Info>
              <Info label="Country">{agent.country ?? '-'}</Info>
              <Info label="Education">{agent.levelOfEducation ?? '-'}</Info>
              <Info label={`Referred By: (${agent.referredBy})`}>
                {agent.referredDetail ?? '-'}
              </Info>
              <Info label="Verified At">{verifiedAt(agent)}</Info>
            </ForFeature>
            <ForFeature feature={FEATURES.AGENT_COMPLIANCE}>
              <Info label="Agent Type">{agent?.ownerType ?? '-'}</Info>
              <Info label="Master Agent">
                {agent?.superAgent?.agentName ? (
                  <Link
                    component={RouterLink}
                    to={`/agents/${agent?.superAgent?.uuid}`}
                  >
                    {agent?.superAgent?.agentName}
                  </Link>
                ) : (
                  '-'
                )}
              </Info>
              <Info label="Territory">
                {agent?.physicalLocation?.name ?? '-'}
              </Info>
              <Info label="Occupation">{agent.businessType ?? '-'}</Info>
              <Info label="Residence">{agent.location ?? '-'}</Info>
              {agent.tradingName && (
                <Info label="Trading name">{getTradingName(agent)}</Info>
              )}
              <Info label="Country">{agent.country ?? '-'}</Info>
              <Info label="Subject to investigation, prison or convicted of bribery or fraud">
                <BooleanBubble
                  value={isExposed(agent?.contract?.fraudReason)}
                />
                {getExposure(agent?.contract?.fraudReason)}
              </Info>
              <Info label="Relative or Friend in Africell or AfriMobileMoney">
                <BooleanBubble
                  value={isExposed(agent?.contract?.friendAtOrganization)}
                />
                {getExposure(agent?.contract?.friendAtOrganization)}
              </Info>
              <Info label="Related to PEP">
                <BooleanBubble
                  value={isExposed(agent?.contract?.politicallyExposedPerson)}
                />
                {getExposure(agent?.contract?.politicallyExposedPerson)}
              </Info>
            </ForFeature>
            <Info label="Updated At">{updatedAt(agent)}</Info>
            <Info label="Created At">{createdAt(agent)}</Info>
            <Info label="App Version">{agent.appVersion ?? '-'}</Info>
          </Grid>
        </Grid>
        <AgentIdPhotos agent={agent} openFullPhoto={openFullPhoto} />
      </CardContent>
    </Card>
  );

const AgentContact = ({ user, agent, openSimNumberForm }) => (
  <Card>
    <CardContent>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Contacts</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            sx={{ mb: 1 }}
            size="small"
            onClick={(e) => openSimNumberForm(null)}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Divider />

      <Info label="Email address">
        <Link href={`mailto://${agent.emailAddress ?? ''}`}>
          {agent.emailAddress ?? '-'}
        </Link>
      </Info>
      {!('simNumbers' in agent) ? (
        <Loading />
      ) : agent?.simNumbers?.length ? (
        getCurrencyGrouped(agent.simNumbers).map((simNumber) => (
          <Box
            key={simNumber.uuid}
            sx={{
              mt: 2,
              '& .MuiGrid-item': { fontSize: '0.9em', fontWeight: 300 },
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box>
                  <Typography variant="h6">
                    {simNumber.isVerified ? (
                      <MuiTooltip title="Verified">
                        <VerifiedIcon color="success" sx={{ mb: '-5px' }} />
                      </MuiTooltip>
                    ) : (
                      <MuiTooltip title="Not Verified">
                        <DangerousIcon color="error" sx={{ mb: '-5px' }} />
                      </MuiTooltip>
                    )}{' '}
                    {getSimCategory(simNumber.category)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ mb: 1 }}
                  size="small"
                  onClick={(e) => openSimNumberForm(simNumber)}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            {hasAnyRoles(user?.role, 'USER_MANAGER') && (
              <Grid container justifyContent="space-between">
                <Grid item>User UUID</Grid>
                <Grid item>
                  <Link
                    to={`/users/${simNumber?.user?.uuid}`}
                    component={RouterLink}
                  >
                    {simNumber?.user?.uuid}
                  </Link>
                </Grid>
              </Grid>
            )}
            <Grid container justifyContent="space-between">
              <Grid item>Name</Grid>
              <Grid item>{simNumber.lineName ?? '-'}</Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>Phone</Grid>
              <Grid item>{simNumber.phoneNumber}</Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>Agent ID</Grid>
              <Grid item>{simNumber.agentId}</Grid>
            </Grid>
            {simNumber._currencies.length >= 1 && (
              <Grid container justifyContent="space-between">
                <Grid item>Balances</Grid>
                <Grid item sx={{ textAlign: 'right' }}>
                  {simNumber._currencies.map((item, i) => (
                    <Box key={`${item.currency}-${i}`}>
                      {item.currency} {numberFormat(item.balance)}
                    </Box>
                  ))}
                </Grid>
              </Grid>
            )}
            <Grid container justifyContent="space-between">
              <Grid item>User</Grid>
              <Grid item>
                <BooleanBubble
                  value={simNumber.user}
                  title={simNumber?.user?.externalId}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))
      ) : (
        <Alert severity="warning">
          This agent has no contacts associated with them. THIS IS VERY WEIRD!
        </Alert>
      )}
    </CardContent>
  </Card>
);

const PhotoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.grey[400]
      : theme.palette.grey[600],
}));

const nationalIdBackground = (url) => {
  if (url) {
    return { backgroundImage: `url(${url ?? ''})` };
  }
  return {};
};

const AgentIdPhotos = ({ agent, openFullPhoto }) => (
  <Grid container spacing={1}>
    <Grid
      item
      md={6}
      xs={12}
      onClick={() => openFullPhoto(agent.nationalIdFrontPhotoUrl)}
    >
      <Typography sx={{ px: 1 }}>Front National ID</Typography>
      <PhotoContainer
        sx={{ ...nationalIdBackground(agent.nationalIdFrontPhotoUrl), mb: 1 }}
      />
    </Grid>
    <Grid
      item
      md={6}
      xs={12}
      onClick={() => openFullPhoto(agent.nationalIdBackPhotoUrl)}
    >
      <Typography sx={{ px: 1 }}>Back National ID</Typography>
      <PhotoContainer
        sx={{ ...nationalIdBackground(agent.nationalIdBackPhotoUrl) }}
      />
    </Grid>
  </Grid>
);

const TransactionTrends = ({ agent, chartId }) => {
  const userUuid = (agent?.simNumbers ?? [])
    .map((s) => s?.user?.uuid)
    .filter((x) => Boolean(x));

  return (
    <Card>
      <EmbeddedChart
        theKey={DASHBOARD_AGENT_DETAIL}
        height="1000px"
        filters={{
          f: {
            id: '88HIB0fO_',
            column: 'userUuid',
            value: userUuid,
          },
        }}
      />
    </Card>
  );
};

const FieldAgentHistory = ({ agent }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="field agent history table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Field Agent</TableCell>
            <TableCell>Comment</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(agent.logs ?? []).map((row) => (
            <TableRow key={row.uuid}>
              <TableCell>{dateFormat(row.loggedAt)}</TableCell>
              <TableCell>{getFieldAgent(row)}</TableCell>
              <TableCell>{row.comment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AgentContract = ({ agent }) => {
  const dispatch = useDispatch();
  const contract = agent?.contract;
  const contractPdfUrl = contract?.contractPdfUrl;
  const statusChanges = useRef(false);
  const { user } = useSelector((state) => state.auth);
  const { status, error } = useSelector((state) => state.agentContractForm);

  useEffect(() => {
    if (status === 'loading') {
      statusChanges.current = true;
    } else if (status === 'editing' && statusChanges.current) {
      statusChanges.current = false;
      if (!error) {
        dispatch(agentFetch(agent.uuid));
      }
    }
  }, [dispatch, agentFetch, agent, status, error, statusChanges]);

  const handleRefresh = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(agentRegenerateContract(agent.uuid));
    },
    [agent]
  );

  return (
    <Card>
      <CardContent>
        {contractPdfUrl ? (
          <>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 1,
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to={contractPdfUrl}
                rel="noreferrer"
                target="_blank"
                download={`${(agent?.agentName ?? '')
                  .replace(/[^A-Za-z]+/g, '-')
                  .toLowerCase()}-${agent.uuid}.pdf`}
                sx={{ mb: 2 }}
              >
                Download
              </Button>
              {hasAnyRoles(user.role, 'COMPLIANCE_MANAGER') &&
                (status == 'loading' ? (
                  <Loading />
                ) : (
                  <Button variant="outlined" onClick={handleRefresh}>
                    Refresh Now
                  </Button>
                ))}
            </Stack>
            <Box
              component="embed"
              src={contractPdfUrl}
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              minHeight="600px"
              height="100%"
              width="100%"
            />
          </>
        ) : (
          <Alert severity="info">
            <Box>Currently no contract generated!</Box>
            <Box>
              <Divider sx={{ my: 2 }} />
              <Box>It may take up to a minute to generate this contract.</Box>

              {error && <Error sx={{ mb: 2 }} error={error} />}

              {hasAnyRoles(user.role, 'COMPLIANCE_MANAGER') &&
                (status == 'loading' ? (
                  <Loading />
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ my: 2, textTransform: 'none' }}
                    onClick={handleRefresh}
                  >
                    Refresh Now
                  </Button>
                ))}
            </Box>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

const AgentApplication = ({ agent }) => {
  const dispatch = useDispatch();
  const contract = agent?.contract;
  const applicationPdfUrl = contract?.applicationPdfUrl;
  const statusChanges = useRef(false);
  const { user } = useSelector((state) => state.auth);
  const { status, error } = useSelector((state) => state.agentContractForm);

  useEffect(() => {
    if (status === 'loading') {
      statusChanges.current = true;
    } else if (status === 'editing' && statusChanges.current) {
      statusChanges.current = false;
      if (!error) {
        dispatch(agentFetch(agent.uuid));
      }
    }
  }, [dispatch, agentFetch, agent, status, error, statusChanges]);

  const handleRefresh = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(agentRegenerateContract(agent.uuid));
    },
    [agent]
  );

  return (
    <Card>
      <CardContent>
        {applicationPdfUrl ? (
          <>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 1,
              }}
            >
              <Button
                variant="contained"
                component={RouterLink}
                to={applicationPdfUrl}
                rel="noreferrer"
                target="_blank"
                download={`${(agent?.agentName ?? '')
                  .replace(/[^A-Za-z]+/g, '-')
                  .toLowerCase()}-${agent.uuid}.pdf`}
                sx={{ mb: 2 }}
              >
                Download
              </Button>
              {hasAnyRoles(user.role, 'COMPLIANCE_MANAGER') &&
                (status == 'loading' ? (
                  <Loading />
                ) : (
                  <Button variant="outlined" onClick={handleRefresh}>
                    Refresh Now
                  </Button>
                ))}
            </Stack>
            <Box
              component="embed"
              src={applicationPdfUrl}
              type="application/pdf"
              frameBorder="0"
              scrolling="auto"
              minHeight="600px"
              height="100%"
              width="100%"
            />
          </>
        ) : (
          <Alert severity="info">
            <Box>Currently no agent KYA PDF generated!</Box>
            <Box>
              <Divider sx={{ my: 2 }} />
              <Box>
                It may take up to a minute to generate this agent KYA PDF.
              </Box>

              {error && <Error sx={{ mb: 2 }} error={error} />}

              {hasAnyRoles(user.role, 'COMPLIANCE_MANAGER') &&
                (status == 'loading' ? (
                  <Loading />
                ) : (
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ my: 2, textTransform: 'none' }}
                    onClick={handleRefresh}
                  >
                    Refresh Now
                  </Button>
                ))}
            </Box>
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

const ComplianceForm = ({ agent, open, handleClose }) => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState('');
  const [comment, setComment] = useState(agent?.contract?.comment ?? '');
  const [externalId, setExternalId] = useState(agent?.externalId ?? '');

  const { status: formStatus, error: formError } = useSelector(
    (state) => state.agentForm
  );

  const statusChanges = useRef(false);

  useEffect(() => {
    setComment(agent?.contract?.comment ?? '');
  }, [agent]);

  useEffect(() => {
    if (formStatus === 'loading') {
      statusChanges.current = true;
    } else if (formStatus === 'editing' && statusChanges.current) {
      statusChanges.current = false;
      if (!formError) {
        handleClose();
      }
    }
  }, [handleClose, formStatus, formError, statusChanges]);

  const handleSave = (e) => {
    e.preventDefault();
    // console.log('RXD: AgentDetail:handleSave', { comment, status });

    dispatch(
      agentSave(agent.uuid, {
        uuid: agent.uuid,
        externalId,
        contract: {
          status,
          comment,
        },
        simNumbers: agent.simNumbers,
      })
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: 'calc(80vw)',
          bgcolor: 'background.paper',
          p: 4,
        }}
        component="form"
        onSubmit={handleSave}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Verify KYA
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Info label="Status" border={false}>
          <Bubble
            label={getAgentStatus(agent)}
            background={getAgentStatusBackground(agent)}
            color={getAgentStatusTextColor(agent)}
          />
        </Info>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <Info label="Signature" border={false}>
              {!agent?.contract?.signatureUrl ? (
                '-'
              ) : (
                <Box
                  component="img"
                  sx={{ maxWidth: '300px' }}
                  src={agent.contract.signatureUrl}
                  alt="Agent Signature"
                  title={agent.contract.signatureUrl}
                />
              )}
            </Info>
          </Grid>
          <Grid item md={4} xs={12}>
            <Info label="Witness Signature" border={false}>
              {!agent?.contract?.witnessSignatureUrl ? (
                '-'
              ) : (
                <Box
                  component="img"
                  sx={{ maxWidth: '300px' }}
                  src={agent.contract.witnessSignatureUrl}
                  alt="Witness Signature"
                  title={agent.contract.witnessSignatureUrl}
                />
              )}
            </Info>
            {agent?.contract?.witnessName ?? '-'}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Info
              label="Subject to investigation, prison or convicted of bribery or fraud"
              border={false}
            >
              <BooleanBubble value={isExposed(agent?.contract?.fraudReason)} />
              {getExposure(agent?.contract?.fraudReason)}
            </Info>
          </Grid>
          <Grid item md={4} xs={12}>
            <Info
              label="Relative or Friend in Africell or AfriMobileMoney"
              border={false}
            >
              <BooleanBubble
                value={isExposed(agent?.contract?.friendAtOrganization)}
              />
              {getExposure(agent?.contract?.friendAtOrganization)}
            </Info>
          </Grid>
          <Grid item md={4} xs={12}>
            <Info label="Related to PEP" border={false}>
              <BooleanBubble
                value={isExposed(agent?.contract?.politicallyExposedPerson)}
              />
              {getExposure(agent?.contract?.politicallyExposedPerson)}
            </Info>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {formError && <Error sx={{ mb: 2 }} error={formError} />}

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Compliance Status</InputLabel>
          <Select
            label="Compliance Status"
            variant="outlined"
            value={status}
            onChange={w(setStatus)}
            required
          >
            {getVerifyStatusOptions(agent?.contract?.status).map(
              ({ label, value }) => (
                <MenuItem
                  key={label}
                  value={value}
                  sx={{
                    color: getComplianceStatusBackground(value),
                    '&:focus': {
                      color: 'black',
                    },
                    '&:hover': {
                      color: 'black',
                    },
                  }}
                >
                  {label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        {status == 'cleared' && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="External ID"
              variant="outlined"
              multiline
              value={externalId}
              onChange={w(setExternalId)}
              helperText="It is optional to populate this field. The External ID from Comviva should be auto-populated when the agent makes their first transaction but it can also be entered and saved to the agent profile as part of this KYA verification step."
            />
          </FormControl>
        )}

        {(status == 'needs-update' || status == 'refused') && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Complaince comment"
              variant="outlined"
              multiline
              minRows={3}
              value={comment}
              onChange={w(setComment)}
            />
          </FormControl>
        )}

        {formStatus === 'loading' ? (
          <Loading />
        ) : !agent?.contract ? (
          <></>
        ) : (
          <Grid container>
            <Grid item md={4} xs={12}>
              <Button variant="contained" type="submit" sx={{ mb: 1 }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

const UpdateKyaForm = ({ agent, open, handleClose }) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState(agent?.contract?.comment ?? '');

  const { status: formStatus, error: formError } = useSelector(
    (state) => state.agentForm
  );

  const statusChanges = useRef(false);

  useEffect(() => {
    setComment(agent?.contract?.comment ?? '');
  }, [agent]);

  useEffect(() => {
    if (formStatus === 'loading') {
      statusChanges.current = true;
    } else if (formStatus === 'editing' && statusChanges.current) {
      statusChanges.current = false;
      if (!formError) {
        dispatch(agentFetch(agent.uuid));
        handleClose();
      }
    }
  }, [handleClose, formStatus, formError, statusChanges]);

  const handleSave = useCallback(
    (e) => {
      e.preventDefault();
      // console.log('RXD: AgentDetail.UpdateKyaForm:handleSave', { comment, status });

      dispatch(
        agentSave(agent.uuid, {
          uuid: agent.uuid,
          contract: {
            status: 'needs-update',
            comment,
          },
          simNumbers: agent.simNumbers,
        })
      );
    },
    [agent, comment]
  );

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box component="form" onSubmit={handleSave}>
        <DialogTitle>Update KYA</DialogTitle>

        <Divider />

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {formError && <Error sx={{ mb: 2 }} error={formError} />}
            Do you want to request this agent or their managing field agent to
            update thier KYA?
            <FormControl fullWidth sx={{ mb: 2, my: 2 }}>
              <TextField
                label="Complaince comment"
                variant="outlined"
                multiline
                required
                minRows={3}
                value={comment}
                onChange={w(setComment)}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>

        {formStatus === 'loading' ? (
          <Loading />
        ) : (
          <DialogActions>
            <Button type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" autoFocus>
              Request Update
            </Button>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};

const VerifyForm = ({ agent, open, handleClose }) => {
  const { isLoading, formError } = useSelector((state) => state.agent);
  const [externalId, setExternalId] = useState('');
  const editStatus = useRef('');

  const dispatch = useDispatch();

  const close = () => {
    setExternalId('');
    dispatch(setFormError(''));
    handleClose();
  };

  useEffect(() => {
    if (isLoading) {
      editStatus.current = 'loading';
    } else {
      if (editStatus.current == 'loading' && !formError) {
        const query = new URLSearchParams(
          'pp=1:300&oo=name&isActive=true&ff=fa.uuid,fa.name'
        );
        dispatch(fieldAgentListFetch(query));

        close();
      } else {
        editStatus.current = '';
      }
    }
  }, [isLoading, formError]);

  useEffect(() => {
    if (!externalId && agent?.externalId) {
      setExternalId(agent?.externalId);
    }
  }, [agent?.externalId]);

  const handleVerifyAgent = (e) => {
    e.preventDefault();

    if (externalId && externalId != agent?.externalId) {
      dispatch(agentVerifyExternalId(agent.uuid, externalId));
    } else {
      dispatch(agentVerify(agent.uuid));
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: 'calc(80vw)',
          bgcolor: 'background.paper',
          p: 4,
        }}
        component="form"
        onSubmit={handleVerifyAgent}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Verify KYA
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {formError && <Error sx={{ mb: 2 }} error={formError} />}

        {shouldShowExternalIdOnVerify(agent) && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="External ID"
              variant="outlined"
              value={externalId}
              type="text"
              onChange={(e) => setExternalId(e.target.value)}
              helperText="An external ID such as SC code"
            />
          </FormControl>
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Button variant="contained" type="submit" sx={{ mb: 1 }}>
              Verify
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ mb: 1 }}
              onClick={close}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

const SimNumberForm = ({ simNumber, agent, open, handleClose }) => {
  const dispatch = useDispatch();
  const { status: formStatus, error: formError } = useSelector(
    (state) => state.simNumberForm
  );

  const statusChanges = useRef(false);
  const [lineName, setLineName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [agentId, setAgentId] = useState('');
  const [category, setCategory] = useState('');
  const [isVerified, setIsVerified] = useState();

  useEffect(() => {
    setLineName(simNumber?.lineName ?? '');
    setPhoneNumber(simNumber?.phoneNumber ?? '');
    setAgentId(simNumber?.agentId ?? '');
    setCategory(simNumber?.category ?? '');
    setIsVerified(simNumber?.isVerified ?? false);
  }, [simNumber]);

  useEffect(() => {
    if (formStatus === 'loading') {
      statusChanges.current = true;
    } else if (formStatus === 'editing' && statusChanges.current) {
      statusChanges.current = false;
      if (!formError) {
        handleClose();
      }
    }
  }, [handleClose, formStatus, formError, statusChanges]);

  const handleSave = (e) => {
    e.preventDefault();

    dispatch(
      simNumberSave(simNumber?.uuid, {
        agent,
        lineName,
        phoneNumber,
        agentId,
        category,
        isVerified,
      })
    );
  };

  const w = (callback) => (e) => callback(e.target.value);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: 'calc(80vw)',
          bgcolor: 'background.paper',
          p: 4,
        }}
        component="form"
        onSubmit={handleSave}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          {Boolean(simNumber?.uuid) ? 'Edit' : 'Add'} Details
        </Typography>

        <Divider sx={{ mb: 2 }} />

        {formError && <Error sx={{ mb: 2 }} error={formError} />}

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Category *</InputLabel>
          <Select
            label="Category *"
            variant="outlined"
            value={category}
            onChange={w(setCategory)}
            required
          >
            {getSimNumberCategories().map((category) => (
              <MenuItem key={category} value={category}>
                {getSimCategory(category)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            label="Phone Number"
            variant="outlined"
            value={phoneNumber}
            type="tel"
            onChange={w(setPhoneNumber)}
            required={!agentId}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            label="Agent ID"
            variant="outlined"
            value={agentId}
            type="number"
            onChange={w(setAgentId)}
            required={!phoneNumber}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            label="Line Name"
            variant="outlined"
            value={lineName}
            onChange={w(setLineName)}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={isVerified}
                onChange={(e) => setIsVerified(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Verified"
          />
        </FormControl>

        {formStatus === 'loading' ? (
          <Loading />
        ) : (
          <Button variant="contained" type="submit" sx={{ mb: 1 }}>
            Save
          </Button>
        )}
      </Box>
    </Modal>
  );
};

const PinForm = ({ agent, open, handleClose }) => {
  const dispatch = useDispatch();
  const { status: formStatus, error: formError } = useSelector(
    (state) => state.pinForm
  );

  const statusChanges = useRef(false);
  const [pin, setPin] = useState('');
  useEffect(() => {
    setPin(
      [0, 0, 0, 0, 0, 0].map((i) => Math.floor(10 * Math.random())).join('')
    );
  }, [open]);

  useEffect(() => {
    if (formStatus === 'loading') {
      statusChanges.current = true;
    } else if (formStatus === 'editing' && statusChanges.current) {
      statusChanges.current = false;
      if (!formError) {
        handleClose();
      }
    }
  }, [handleClose, formStatus, formError, statusChanges]);

  const handleSave = (e) => {
    e.preventDefault();

    dispatch(
      pinSave(agent.uuid, {
        pin,
        oldPin: '000000',
      })
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          width: 'calc(80vw)',
          bgcolor: 'background.paper',
          p: 4,
        }}
        component="form"
        onSubmit={handleSave}
      >
        <Typography variant="h5" sx={{ mb: 2 }}>
          Reset Agent PIN
        </Typography>

        <Divider sx={{ mb: 2 }} />

        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>
            STOP! You are about to permanently change the agent's Opareta PIN.
            Please only proceed if you want to do this!
          </AlertTitle>
        </Alert>

        {formError && <Error sx={{ mb: 2 }} error={formError} />}

        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            label="PIN"
            variant="outlined"
            value={pin}
            type="text"
            disabled={true}
            required
          />
        </FormControl>

        {formStatus === 'loading' ? (
          <Loading />
        ) : (
          <Button variant="contained" type="submit" sx={{ mb: 1 }}>
            Save
          </Button>
        )}
      </Box>
    </Modal>
  );
};

const agentOptions = [
  'Assign Field Agent',
  'Make Loan Offer',
  'Reset PIN',
  'Verify KYC',
];

export default function AgentActionsSplitButton() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    console.info(`You clicked ${agentOptions[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick}>{agentOptions[selectedIndex]}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {agentOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 4}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
