import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ConfirmReassignmentsDialog = ({
  open,
  onClose,
  onConfirm,
  confirming,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        Are you sure you want to reassign these agent(s)?
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }}>
          This implies that the agent(s) will be automatically reassigned to the
          new Team Field Agent in their new territory.
        </DialogContentText>
      </DialogContent>
      {confirming && (
        <DialogContent sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </DialogContent>
      )}

      {!confirming && (
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button variant="contained" onClick={onClose} color="error">
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm} autoFocus>
            Yes, I confirm
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
